/* eslint-disable */

import atlasServerData from "./atlasServerData.js"; // 다른 이름으로도 가능


import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import styles from "./Atlasgrid.module.css";
import Canvas from "../components/Canvas";

// HTML 부분
import Slider from "@mui/material/Slider";

import moment from "moment";

function Atlasgrid() {
  const locationCheat = useLocation();
  const serverDataRef = useRef(new atlasServerData());

  const g_fetchedData_Ref = useRef(null); // 데이터 저장 변수
  const g_cheat_Ref = useRef(false);

  // 상수
  const GRID_ONLINE = 1;
  const GRID_OFFLINE = 0;

  const NUM_GRID_ROW = 6;
  const NUM_GRID_COL = 6;
  const NUM_TOTAL_GRID = NUM_GRID_COL * NUM_GRID_ROW;

  const CANVAS_WIDTH = 2304;
  const CANVAS_HEIGHT = 2304;
  const GRID_WIDTH = CANVAS_WIDTH / NUM_GRID_COL; // 2304/6 = 384
  const GRID_HEIGHT = CANVAS_HEIGHT / NUM_GRID_ROW;

  const GRID_NUMBER_TEXT = "90px";
  const GRID_NUMBER_INC_DEC_TEXT = "40px";
  const GRID_PREV_NUMBER_TEXT = "40px";

  const GRID_NUMBER_COLOR = "#FFFFFF";
  const GRID_NUMBER_INC_COLOR = "#44FF44";
  const GRID_NUMBER_DEC_COLOR = "#FF4444";
  const GRID_NUMBER_5MIN_COLOR = "#FFFFFFFF";
  const GRID_NUMBER_10MIN_COLOR = "#FFFFFF88";
  const GRID_NUMBER_20MIN_COLOR = "#FFFFFF33";

  const GRID_NUMBER_POINT_X = 160;
  const GRID_NUMBER_POINT_Y = 205;

  const GRID_NUMBER_5MIN_POINT_X = 100;
  const GRID_NUMBER_5MIN_POINT_Y = 335;
  const GRID_NUMBER_10MIN_POINT_X = 60;
  const GRID_NUMBER_10MIN_POINT_Y = 350;
  const GRID_NUMBER_20MIN_POINT_X = 20;
  const GRID_NUMBER_20MIN_POINT_Y = 335;

  const DAYTIME_POINT_X = 335;
  const DAYTIME_POINT_Y = 15;
  const DAYTIME_COLOR = "#DDDDDD";
  const DAYTIME_TEXT = "30px";
  const INGAMETIME_TEXT = "30px";

  const GRID_NAME_POINT_X = 10;
  const GRID_NAME_POINT_Y = 15;
  const GRID_NAME_COLOR = "#DDDDDD";
  const GRID_NAME_TEXT = "40px";

  const GRID_DEFAULT_LINE_COLOR = "#888888";
  const GRID_LINE_COLOR = GRID_DEFAULT_LINE_COLOR;
  const GRID_LINE_THICKNESS = 4;

  const GRID_OFFLINE_COLOR = "#00000055";
  const GRID_ONLINE_COLOR = "#22222222";
  const GRID_YELLOW_COLOR = "#88880044";
  const GRID_ORANGE_COLOR = "#FF770044";
  const GRID_RED_COLOR = "#FF000044";

  const GRID_YELLOW_NUMBER = 7;
  const GRID_ORANGE_NUMBER = 10;
  const GRID_RED_NUMBER = 15;

  // NEARDING BOX
  const NEARDING_FILL_COLOR = "#44444488";
  const NEARDING_USE_STROKE = true;
  const NEARDING_STROKE_COLOR = "#77777788";
  const NEARDING_STROKE_THICKNESS = 2;

  // NEARDING INFO
  const NEARDING_TEXT = "40px";
  const NEARDING_TIME_TEXT = "30px";

  const NEARDING_SHORT_COLOR = "#AAAA44";
  const NEARDING_YELLOW_NUMBER = 2;
  const NEARDING_ORANGE_NUMBER = 4;
  const NEARDING_RED_NUMBER = 8;

  const NEARDING_N_SHORT_POINT_X = 145; //
  const NEARDING_N_SHORT_POINT_Y = 80; //
  const NEARDING_E_SHORT_POINT_X = 280;
  const NEARDING_E_SHORT_POINT_Y = 170;
  const NEARDING_S_SHORT_POINT_X = 145; //
  const NEARDING_S_SHORT_POINT_Y = 310; //
  const NEARDING_W_SHORT_POINT_X = 85;
  const NEARDING_W_SHORT_POINT_Y = 170;

  const NEARDING_LONG_COLOR = "#AAAAAA";
  const NEARDING_N_LONG_POINT_X = 145; //
  const NEARDING_N_LONG_POINT_Y = 40; //
  const NEARDING_E_LONG_POINT_X = 280;
  const NEARDING_E_LONG_POINT_Y = 215;
  const NEARDING_S_LONG_POINT_X = 145; //
  const NEARDING_S_LONG_POINT_Y = 350;
  const NEARDING_W_LONG_POINT_X = 85; //
  const NEARDING_W_LONG_POINT_Y = 215; //

  const WIND_TIME_X = 340;
  const WIND_TIME_Y = 360;
  const WIND_TIME_COLOR = "white";
  const WIND_TIME_TEXT = "30px";
  const INGAME_TIME_MULTIPLIER = 28.57142857;

  const WIND_SYMBOL_X = 340;
  const WIND_SYMBOL_Y = 310;
  const WIND_SYMBOL_COLOR = "white";
  const WIND_SYMBOL_STROKE = true;
  const WIND_SYMBOL_STROKE_WIDTH = 2;

  const BOSS_ICON_SIZE = 90;
  const BOSS_ICON_ALPHA = 0.8;

  const [TotalPlayer, setTotalPlayer] = useState(0);
  const [TotalPlayer_10min, setTotalPlayer_10min] = useState(0);
  const [TotalPlayer_20min, setTotalPlayer_20min] = useState(0);
  const [TotalPlayer_5min, setTotalPlayer_5min] = useState(0);
  const [LastServerUpdatedAt, setLastServerUpdatedAt] = useState("wait");

  const [sliderNeardingLong, setSliderNeardingLong] = useState(600);
  const [sliderNeardingShort, setSliderNeardingShort] = useState(120);

  // HTML관련
  const [isCheckedBoss, setIsCheckedBoss] = useState(false);
  const [isCheckedTradeWind, setIsCheckedTradeWind] = useState(false);
  const [isCheckedIslands, setIsCheckedIslands] = useState(false);
  const [isCheckedNearding, setIsCheckedNearding] = useState(true);
  const [isCheckedPrevGridNumber, setIsCheckedPrevGridNumber] = useState(true);
  const [isCheckedGridingLine, setIsCheckedGridingLine] = useState(false);
  const [isCheckedWindPower, setIsCheckedWindPower] = useState(true);

  useEffect(() => {
    // 초기 fetchData 실행 (필수))
    fetchData();

    // 치트 동작여부 설정
    const searchParams = new URLSearchParams(locationCheat.search);
    const specificText = searchParams.get("username"); // 'text' 쿼리 파라미터 읽기

    if (specificText === "smoky") {
      g_cheat_Ref.current = true;
    }

    const savedNeardingLong = localStorage.getItem(`neardingLongStatus`);
    if (savedNeardingLong) setSliderNeardingLong(JSON.parse(savedNeardingLong));

    const savedNeardingShort = localStorage.getItem(`neardingShortStatus`);
    if (savedNeardingShort)
      setSliderNeardingShort(JSON.parse(savedNeardingShort));

    const savedBoss = localStorage.getItem(`bossStatus`);
    if (savedBoss) setIsCheckedBoss(JSON.parse(savedBoss));

    // 설정값 불러오기
    const savedTradeWind = localStorage.getItem(`tradeWindStatus`);
    if (savedTradeWind) setIsCheckedTradeWind(JSON.parse(savedTradeWind));

    const savedIslands = localStorage.getItem(`islandsStatus`);
    if (savedIslands) setIsCheckedIslands(JSON.parse(savedIslands));

    const savedNearding = localStorage.getItem(`neardingStatus`);
    if (savedNearding) setIsCheckedNearding(JSON.parse(savedNearding));

    const savedPrevGridNumber = localStorage.getItem(`prevGridNumberStatus`);
    if (savedPrevGridNumber)
      setIsCheckedPrevGridNumber(JSON.parse(savedPrevGridNumber));

    const savedGridingLine = localStorage.getItem(`gridingLineStatus`);
    if (savedGridingLine) setIsCheckedGridingLine(JSON.parse(savedGridingLine));

    const savedWindPower = localStorage.getItem(`windPowerStatus`);
    if (savedWindPower) setIsCheckedWindPower(JSON.parse(savedWindPower));
  }, []);

  const lastEntryID_Ref = useRef(0);

  // offcreen canvas for BG
  const canvasBG = document.createElement("canvas");
  const ctxBG = canvasBG.getContext("2d");

  let image_data_loaded = false;

  const g_currentTime_Ref = useRef(0);
  const g_lastTime_Ref = useRef(0);
  const g_deltaTime_Ref = useRef(0);
  const g_timeIntervalNextFetchData_Ref = useRef(10);

  const g_dataRequestTime_Ref = useRef(0);
  const g_dataReceiveTime_Ref = useRef(0);
  const g_dataRequestCount_Ref = useRef(0);
  const g_nextFetchDataTime_Ref = useRef(0);

  const g_lastProcessingTime_Ref = useRef(0);

  function secondsToTimeString(seconds) {
    // 초를 시간, 분, 초로 변환
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // 두 자리 숫자로 변환
    //return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function secondsToTimeStringMS(seconds) {
    // 초를 시간, 분, 초로 변환
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // 두 자리 숫자로 변환
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  }

  function get_index(col, row) {
    return col * NUM_GRID_ROW + row;
  }

  function get_col(index) {
    return Math.floor(index / NUM_GRID_ROW);
  }
  function get_row(index) {
    return index % NUM_GRID_ROW;
  }

  const handleSliderNeardingLongChange = (event, newValue) => {
    if (newValue < 600) newValue = 600;
    setSliderNeardingLong(newValue);
    localStorage.setItem(`neardingLongStatus`, JSON.stringify(newValue));
  };

  const handleSliderNeardingShortChange = (event, newValue) => {
    if (newValue < 60) newValue = 60;
    setSliderNeardingShort(newValue);
    localStorage.setItem(`neardingShortStatus`, JSON.stringify(newValue));
  };

  const fetchData = async () => {
    const url = new URL(
      "https://atlas.kggstudio.com/atlasgrid/monitor/?what=gridinfo_atlaswars"
    ); // 배포환경용 (실제아이피사용해야함)
    url.searchParams.append("lastEntryID", lastEntryID_Ref.current);

    g_dataRequestTime_Ref.current = Date.now() / 1000;

    try {
      const response = await fetch(url);
      const newJsonData = await response.json();
      if (newJsonData.success) {
        g_fetchedData_Ref.current = newJsonData.data.grid_data;
        g_dataReceiveTime_Ref.current = Date.now() / 1000;
        g_dataRequestCount_Ref.current = g_dataRequestCount_Ref.current + 1;
      } else {
        // 받은데이터 결과표시
        //console.log(lastEntryID_Ref.current + " 요청에 대한 답변: " + newJsonData.error_message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function convertSecondsToDHMS(seconds) {
    let days = Math.floor(seconds / 86400);
    seconds %= 86400;

    let hours = Math.floor(seconds / 3600);
    seconds %= 3600;

    let minutes = Math.floor(seconds / 60);
    let secs = seconds % 60;

    let result = "";

    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m `;
    }
    result += `${secs}s`;

    return result.trim();
  }

  function utcTimeToLocalTimeString(utcTimestamp) {
    // UTC 타임스탬프를 Date 객체로 생성
    let localTime = moment.utc(utcTimestamp * 1000).local();
    const date = new Date(localTime);

    // 지역 시간으로 포맷팅
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    const userLanguage = navigator.language || navigator.userLanguage;

    const localDateString = date.toLocaleString(userLanguage, options);

    return localDateString;
  }

  function timeAgoFromNow(time) {
    return g_currentTime_Ref.current - time;
  }
  function timeAgoFromNowInSeconds(time) {
    return Math.floor(g_currentTime_Ref.current - time);
  }

  function timeAgoInSeconds(serverTimestamp, postTimestamp) {
    const currentTimeInSeconds = g_currentTime_Ref.current;
    const serverTimeDiff = currentTimeInSeconds - serverTimestamp; // 서버 시간과 클라이언트 시간의 차이
    const timeAgoInSeconds = currentTime - (postTimestamp + serverTimeDiff); // 서버 시간을 보정한 게시글 생성 시간과의 차이

    return timeAgoInSeconds;
  }

  function loadImage(images, callback) {
    let loadedCount = 0;
    images.forEach((imgInfo) => {
      const img = new Image();
      img.onload = () => {
        imgInfo.image = img;
        imgInfo.loaded = true;
        //console.log(img.src + ' loaded'); // 왜 여러번 호출?
        loadedCount++;

        if (loadedCount === images.length) {
          callback();
        }
      };
      img.onerror = () => {
        console.log(img.src + " loading error");
      };

      img.src = imgInfo.src;
    });
  }

  function getNeighbors(cellId) {
    const GRID_SIZE = 6;
    const TOTAL_CELLS = GRID_SIZE * GRID_SIZE;

    let row = Math.floor(cellId / GRID_SIZE);
    let col = cellId % GRID_SIZE;

    let left = col === 0 ? cellId + (GRID_SIZE - 1) : cellId - 1;
    let right = col === GRID_SIZE - 1 ? cellId - (GRID_SIZE - 1) : cellId + 1;
    let up = row === 0 ? cellId + TOTAL_CELLS - GRID_SIZE : cellId - GRID_SIZE;
    let down =
      row === GRID_SIZE - 1
        ? cellId - TOTAL_CELLS + GRID_SIZE
        : cellId + GRID_SIZE;

    return [left, right, up, down];
  }

  const BGImages = [
    {
      src: require("../images/bg.png"),
      globalAlpha: 0.5,
      imageType: "bg",
      x: 4,
      y: 1,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      loaded: false,
    },
    {
      src: require("../images/aw_s2_islands.jpg"),
      globalAlpha: 0.5,
      imageType: "Islands",
      x: 0,
      y: 0,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      loaded: false,
    },
    {
      src: require("../images/aw_s2_tradewind.png"),
      globalAlpha: 0.3,
      imageType: "TradeWind",
      x: 0,
      y: 0,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      loaded: false,
    },
    {
      src: require("../images/crab.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 5.2,
      y: 384 * 5.6,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/drake.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 5.6,
      y: 384 * 5.2,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/pig.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.6,
      y: 384 * 5.45,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/cyclops.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 5.25,
      y: 384 * 0.2,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/yeti.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 5.6,
      y: 384 * 0.6,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/crocodile.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.6,
      y: 384 * 0.4,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/fireele.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.3,
      y: 384 * 0.7,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/rockele.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.2,
      y: 384 * 0.2,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/hydra.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.25,
      y: 384 * 5.2,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/kraken.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 2.42,
      y: 384 * 2.42,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
    {
      src: require("../images/scorpion.png"),
      globalAlpha: BOSS_ICON_ALPHA,
      imageType: "BossIcon",
      x: 384 * 0.2,
      y: 384 * 5.65,
      width: BOSS_ICON_SIZE,
      height: BOSS_ICON_SIZE,
      loaded: false,
    },
  ];

  function CB_bossIcon_Change(e) {
    setIsCheckedBoss(e.target.checked); // 체크 상태 변경
    localStorage.setItem("bossStatus", JSON.stringify(e.target.checked));
    initBG();
  }
  function CB_tradewind_Change(e) {
    setIsCheckedTradeWind(e.target.checked); // 체크 상태 변경
    localStorage.setItem("tradeWindStatus", JSON.stringify(e.target.checked));
  }
  function CB_islands_Change(e) {
    setIsCheckedIslands(e.target.checked); // 체크 상태 변경
    localStorage.setItem("islandsStatus", JSON.stringify(e.target.checked));
  }
  function CB_nearding_Change(e) {
    setIsCheckedNearding(e.target.checked); // 체크 상태 변경
    localStorage.setItem("neardingStatus", JSON.stringify(e.target.checked));
  }
  function CB_prevGridNumber_Change(e) {
    setIsCheckedPrevGridNumber(e.target.checked); // 체크 상태 변경
    localStorage.setItem(
      "prevGridNumberStatus",
      JSON.stringify(e.target.checked)
    );
  }
  function CB_gridingLine_Change(e) {
    setIsCheckedGridingLine(e.target.checked); // 체크 상태 변경
    localStorage.setItem("gridingLineStatus", JSON.stringify(e.target.checked));
  }
  function CB_windPower_Change(e) {
    setIsCheckedWindPower(e.target.checked); // 체크 상태 변경
    localStorage.setItem("windPowerStatus", JSON.stringify(e.target.checked));
  }
  const BoatImage = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      BoatImage.current = img;
      BoatImage.loaded = true;
    };
    img.onerror = () => {
      console.log("Error loading boat.png");
    };
    img.src = require("../images/boat.png");
  }, []);

  // 접속자수, 서버상태, timestamp 구분없이 비교가능 (배열크기가 같아야함)
  function isGridDataEqual(a, b) {
    // 두 배열의 길이가 다르면 false 반환
    if (a.length !== b.length) {
      return false;
    }

    // 배열의 모든 요소가 같은지 비교
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false;
      }
    }

    // 모든 요소가 같으면 true 반환
    return true;
  }

  function process_next_fetchData() {
    if (
      g_nextFetchDataTime_Ref.current < g_currentTime_Ref.current &&
      g_fetchedData_Ref.current == null
    ) {
      fetchData();
      console.log("fetchData() 호출:" + g_currentTime_Ref.current);
      g_nextFetchDataTime_Ref.current =
        g_currentTime_Ref.current + g_timeIntervalNextFetchData_Ref.current;
    }
  }

  function process_recent_grid_data() {
    if (g_fetchedData_Ref.current == null) return false;

    //서버에서 가져온값
    const json_data = g_fetchedData_Ref.current;
    //클라이언트에 저장된값
    const serverData = serverDataRef.current;

    // 반응은 왔지만 추가데이터가 없으면 변화없이 함수종료
    if (json_data.listGridInfo.length == 0) {
      g_fetchedData_Ref.current = null;
      return false;
    }

    const last_json_data =
      json_data.listGridInfo[json_data.listGridInfo.length - 1];

    // header 데이터 갱신
    serverData.lastServerResponseTimeStamp =
      json_data.header.current_server_time;
    serverData.lastDataTimeStamp = json_data.header.total_lastTimeStamp;
    serverData.nPlayer = json_data.header.total_players;

    //console.log("데이터 요청시간:", g_dataRequestTime_Ref.current );
    //console.log("데이터 받은시간:", g_dataReceiveTime_Ref.current );
    //console.log("현재 클라시간: " + g_currentTime_Ref.current)
    //console.log("현재 서버시간: " + serverData.lastServerResponseTimeStamp)
    //console.log("현재 서버 마지막 데이터: " + serverData.lastDataTimeStamp )

    const timeDiffClient_ServerData =
      g_dataRequestTime_Ref.current - serverData.lastDataTimeStamp;

    if (g_dataRequestCount_Ref.current > 2 && timeDiffClient_ServerData > 1) {
      //console.log("@@@@@@@@@@@@@@@@다음fetch()시간 변경: -",timeDiffClient_ServerData);
      g_nextFetchDataTime_Ref.current =
        g_nextFetchDataTime_Ref.current - (timeDiffClient_ServerData - 0.5);
    }
    console.log("최신 데이터와 현재시간 차이" + timeDiffClient_ServerData);

    // DayTime 데이터 갱신
    serverData.setDayTime(last_json_data.dayTime);
    // DayTimeTimeStamp
    serverData.setDayTimeTimeStamp(last_json_data.newDayTime_timeStamp);

    // nPlayer 데이터 갱신
    for (let i = 0; i < json_data.listGridInfo.length; i++) {
      const currentGridData = json_data.listGridInfo[i];

      // 서버데이터 보관 객체에 유저정보가 없으면 그냥추가 있으면 이전데이터와 비교후 다른경우에만 추가.
      if (serverData.listGridnPlayers.length == 0) {
        serverData.addGridnPlayerData(
          currentGridData.timeStamp,
          currentGridData.grid_nPlayers
        );
      } else {
        const current_nPlayer = serverData.getLastGridnPlayerData();
        if (current_nPlayer) {
          if (
            isGridDataEqual(
              current_nPlayer.nPlayer,
              currentGridData.grid_nPlayers
            ) == false
          ) {
            serverData.addGridnPlayerData(
              currentGridData.timeStamp,
              currentGridData.grid_nPlayers
            );
          }
        }
      }
    }

    // GRID STATUS: ONLINE? OFFLINE
    for (let i = 0; i < json_data.listGridInfo.length; i++) {
      const currentGridData = json_data.listGridInfo[i];

      if (serverData.listGridStatus.length == 0) {
        serverData.addGridStatusData(
          currentGridData.timeStamp,
          currentGridData.grid_status
        );
      } else {
        const last_gridStatus = serverData.getLastGridStatusData();
        if (last_gridStatus) {
          if (
            isGridDataEqual(
              last_gridStatus.gridStatusArray,
              currentGridData.grid_status
            ) == false
          ) {
            serverData.addGridStatusData(
              currentGridData.timeStamp,
              currentGridData.grid_status
            );
          }
        }
      }
    }

    // GRID NEARDING INFO
    for (let i = 0; i < json_data.listGridInfo.length; i++) {
      const current_neardingInfo = serverData.getLastNeardingInfoData();
      const currentGridData = json_data.listGridInfo[i];

      if (current_neardingInfo == null)
        serverData.addNeardingInfoData(
          currentGridData.timeStamp,
          currentGridData.nearding_info
        );
      else {
        if (current_neardingInfo.timestamp < currentGridData.timeStamp)
          serverData.addNeardingInfoData(
            currentGridData.timeStamp,
            currentGridData.nearding_info
          );
      }
    }

    // GRID GIRDING INFO
    for (let i = 0; i < json_data.listGridInfo.length; i++) {
      const current_neardingInfo = serverData.getLastGridingInfoData();
      const currentGridData = json_data.listGridInfo[i];

      if (current_neardingInfo == null)
        serverData.addGridingInfoData(
          currentGridData.timeStamp,
          currentGridData.griding_info
        );
      else {
        if (current_neardingInfo.timestamp < currentGridData.timeStamp)
          serverData.addGridingInfoData(
            currentGridData.timeStamp,
            currentGridData.griding_info
          );
      }
    }
    //console.log(serverData);

    // CLEAN OLD DATA
    //serverData.cleanOldData();

    lastEntryID_Ref.current = serverData.lastDataTimeStamp;

    //console.log(json_data);

    g_fetchedData_Ref.current = null;

    return true;
  }

  const draw = (context) => {
    ////////////////////////////////////////////////////////////////////////////////////////
    // UPDATE == UPDATE == UPDATE == UPDATE == UPDATE == UPDATE == UPDATE == UPDATE == UPDATE
    ////////////////////////////////////////////////////////////////////////////////////////

    // 전역변수 데이터 갱신 Update()
    g_currentTime_Ref.current = Date.now() / 1000;
    g_deltaTime_Ref.current =
      g_currentTime_Ref.current - g_lastTime_Ref.current;
    g_lastTime_Ref.current = g_currentTime_Ref.current;

    process_next_fetchData();

    const serverData = serverDataRef.current;

    // 서버에서 받아온 데이터 갱신 (4초가 지나야 할수있음)
    if (timeAgoFromNow(g_lastProcessingTime_Ref.current) > 4) {
      if (process_recent_grid_data() == true) {
        //console.log("Recent data processed. At: " + g_lastProcessingTime_Ref.current);
        serverData.setReady(true);
      }

      g_lastProcessingTime_Ref.current = g_currentTime_Ref.current;

      //console.log("Current Client Time: " + g_currentTime_Ref.current + " 이하 현재 serverData 내용");
      //console.log(serverData);
    }

    // ServerData 자체 데이터 갱신
    serverData.Update(g_currentTime_Ref.current, g_deltaTime_Ref.current);

    // HTML Elements 갱신
    setTotalPlayer(serverData.nPlayer);
    setTotalPlayer_10min(serverData.nPlayer_10min);
    setTotalPlayer_20min(serverData.nPlayer_20min);
    setTotalPlayer_5min(serverData.nPlayer_5min);

    let localTimeString = utcTimeToLocalTimeString(
      serverData.lastServerResponseTimeStamp
    );
    setLastServerUpdatedAt(localTimeString);

    ////////////////////////////////////////////////////////////////////////////////////////
    // 아래는 그리기영역 DRAW == DRAW == DRAW == DRAW ==  DRAW == DRAW ==  DRAW == DRAW == DRAW
    ////////////////////////////////////////////////////////////////////////////////////////
    context.save();

    //배경화면과 그리드라인, 보스이미지 여기서
    if (image_data_loaded === false) {
      loadImage(BGImages, () => {
        canvasBG.width = CANVAS_WIDTH;
        canvasBG.height = CANVAS_HEIGHT;

        image_data_loaded = true;
        initBG();
      });
    }
    context.drawImage(canvasBG, 0, 0, canvasBG.width, canvasBG.height);

    // 아래에 다이나믹한것 그리기
    if (serverData.getReady()) {
      drawServerInfo(context);
      //if (g_cheat_Ref.current) {
        drawGridingLine(context);
        drawDayTimeInfo(context);
        drawNeardingInfo(context);
        drawWindpowerInfo(context);
      //}
    }

    context.restore();
  };

  function _drawGridColor(
    ctx,
    fillColor,
    col,
    row,
    stroke = true,
    strokeColor = GRID_LINE_COLOR,
    lineWidth = GRID_LINE_THICKNESS
  ) {
    const x = col * GRID_WIDTH;
    const y = row * GRID_HEIGHT;
    const w = GRID_WIDTH;
    const h = GRID_HEIGHT;

    ctx.fillStyle = fillColor;
    ctx.fillRect(x, y, w, h);
    if (stroke == true) {
      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = lineWidth;
      ctx.strokeRect(x, y, w, h);
    }
  }

  function _drawNeardingColor(
    ctx,
    fillColor,
    col,
    row,
    direction,
    stroke = NEARDING_USE_STROKE,
    strokeColor = NEARDING_STROKE_COLOR,
    lineWidth = NEARDING_STROKE_THICKNESS
  ) {
    let point1 = [0, 0];
    let point2 = [0, 0];
    let point3 = [0, 0];
    let point4 = [0, 0];
    let point5 = [0, 0];

    const x = col * GRID_WIDTH;
    const y = row * GRID_HEIGHT;

    switch (direction) {
      case "N":
        point1 = [128, 0];
        point2 = [128, 90];
        point3 = [192, 120];
        point4 = [256, 90];
        point5 = [256, 0];
        break;
      case "E":
        point1 = [384, 128];
        point2 = [294, 128];
        point3 = [264, 192];
        point4 = [294, 256];
        point5 = [384, 256];
        break;
      case "W":
        point1 = [0, 128];
        point2 = [90, 128];
        point3 = [120, 192];
        point4 = [90, 256];
        point5 = [0, 256];
        break;
      case "S":
        point1 = [128, 384];
        point2 = [128, 294];
        point3 = [192, 264];
        point4 = [256, 294];
        point5 = [256, 384];
        break;
      default:
        console.log("_drawNeardingColor(): Invalid direction");
        //잘못된값 들어오면 아예 안그려버리기
        return;
    }

    // 삼각형 그리기
    ctx.beginPath();
    ctx.moveTo(point1[0] + x, point1[1] + y);
    ctx.lineTo(point2[0] + x, point2[1] + y);
    ctx.lineTo(point3[0] + x, point3[1] + y);
    ctx.lineTo(point4[0] + x, point4[1] + y);
    ctx.lineTo(point5[0] + x, point5[1] + y);
    ctx.closePath();

    // 내부 색상 채우기 (투명도 0.5)
    ctx.fillStyle = fillColor;
    ctx.fill();

    if (stroke) {
      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    }
  }

  function drawServerInfo(ctx) {
    const serverData = serverDataRef.current;
    const last_nPlayer = serverDataRef.current.getLastGridnPlayerData();
    const currentTime = g_currentTime_Ref.current;

    if (last_nPlayer == null) return;

    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    const gridData_nPlyaer_5min = serverData.getLatestDataBefore(
      currentTime - 300
    );
    const gridData_nPlyaer_10min = serverData.getLatestDataBefore(
      currentTime - 600
    );
    const gridData_nPlyaer_20min = serverData.getLatestDataBefore(
      currentTime - 1200
    );

    const gridStatusArray_5min = serverData.getLatestGridStatusDataBefore(
      currentTime - 300
    );
    const gridStatusArray_10min = serverData.getLatestGridStatusDataBefore(
      currentTime - 600
    );
    const gridStatusArray_20min = serverData.getLatestGridStatusDataBefore(
      currentTime - 1200
    );

    const IncreasedInfo = serverData.getIncreasedGridingInfoBetween(
      currentTime - 120,
      currentTime
    );
    const DecreasedInfo = serverData.getDecreasedGridingInfoBetween(
      currentTime - 120,
      currentTime
    );
    //console.log("시작");
    for (let i = 0; i < NUM_TOTAL_GRID; i++) {
      const col = get_col(i);
      const row = get_row(i);
      const nPlayer = last_nPlayer.nPlayer[i];

      // 그리드 상태 확인
      const isGridOnline =
        serverData.getLastGridStatusData().gridStatusArray[i];
      const gridStatus_duration = Math.floor(
        currentTime - serverData.getLastGridStatusData().timeStampArray[i]
      );

      //console.log(serverData.getLastGridStatusData().timeStampArray);
      //console.log(gridStatus_duration);

      // 접속자수에 따른 배경색
      //_drawGridColor(ctx, colorCodes[nPlayer > 15 ? 15 : nPlayer], col, row);
      if (isGridOnline == false) _drawGridColor(ctx, "#00000088", col, row);
      else if (nPlayer == 0) _drawGridColor(ctx, "#00000000", col, row);
      else if (nPlayer < 10) _drawGridColor(ctx, "#00000000", col, row);
      else if (nPlayer < 15) _drawGridColor(ctx, "#FFFF0033", col, row);
      else _drawGridColor(ctx, "#FF110033", col, row);

      const nPlayerDiffInc = IncreasedInfo.totalIncreasedData[i];
      const DiffIncTimeStamp = IncreasedInfo.maxTimeStamp[i];
      const nPlayerDiffDec = DecreasedInfo.totalDecreasedData[i];
      const DiffDecTimeStamp = DecreasedInfo.maxTimeStamp[i];

      // 현재 접속자수 표시
      ctx.fillStyle = GRID_NUMBER_COLOR;
      ctx.font = GRID_NUMBER_TEXT + " GmarketSansLight";
      if (isGridOnline == false) {
        // OFFLINE TEXT 표시
        ctx.fillStyle = "#FF000088";
        ctx.font = "50px GmarketSansLight";
        ctx.fillText(
          "OFFLINE",
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 35,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y
        );
        // OFFLINE 시간 표시
        ctx.fillText(
          convertSecondsToDHMS(gridStatus_duration),
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 35,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y + 60
        );
      } else if (nPlayerDiffInc != 0 || nPlayerDiffDec != 0)
        ctx.fillText(
          nPlayer,
          col * GRID_WIDTH + GRID_NUMBER_POINT_X,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y
        );
      else
        ctx.fillText(
          nPlayer,
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 35,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y
        );

      if (nPlayerDiffInc > 0) {
        ctx.fillStyle = GRID_NUMBER_INC_COLOR;
        ctx.font = GRID_NUMBER_INC_DEC_TEXT + " GmarketSansLight";
        ctx.fillText(
          "+" + nPlayerDiffInc,
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 50,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y - 30
        );
        ctx.font = "25px GmarketSansLight";
        ctx.fillStyle = "#999999";
        ctx.fillText(
          Math.floor(currentTime - DiffIncTimeStamp),
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 95,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y - 30
        );
      }
      if (nPlayerDiffDec < 0) {
        ctx.fillStyle = GRID_NUMBER_DEC_COLOR;
        ctx.font = GRID_NUMBER_INC_DEC_TEXT + " GmarketSansLight";
        ctx.fillText(
          nPlayerDiffDec,
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 50,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y + 10
        );
        ctx.font = "25px GmarketSansLight";
        ctx.fillStyle = "#999999";
        ctx.fillText(
          Math.floor(currentTime - DiffDecTimeStamp),
          col * GRID_WIDTH + GRID_NUMBER_POINT_X + 95,
          row * GRID_HEIGHT + GRID_NUMBER_POINT_Y + 10
        );
      }

      ////////////////////////////////////////////////////////////////////////
      // 과거 접속자수 표시
      if (isCheckedPrevGridNumber) {
        ctx.font = GRID_PREV_NUMBER_TEXT + " GmarketSansLight";

        //온오프라인 먼저체크 후 접속자수 데이터 체크
        if (gridStatusArray_5min) {
          if (gridStatusArray_5min[i] == GRID_ONLINE && gridData_nPlyaer_5min) {
            const nPlayer_5min = gridData_nPlyaer_5min.nPlayer[i];

            ctx.fillStyle = GRID_NUMBER_5MIN_COLOR;
            ctx.fillText(
              nPlayer_5min,
              col * GRID_WIDTH + GRID_NUMBER_5MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_5MIN_POINT_Y
            );
          } else if (gridStatusArray_5min[i] == GRID_OFFLINE) {
            ctx.fillStyle = "#FF000088";
            ctx.fillText(
              "X",
              col * GRID_WIDTH + GRID_NUMBER_5MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_5MIN_POINT_Y
            );
          }
        }

        if (gridStatusArray_10min) {
          if (
            gridStatusArray_10min[i] == GRID_ONLINE &&
            gridData_nPlyaer_10min
          ) {
            const nPlayer_10min = gridData_nPlyaer_10min.nPlayer[i];

            ctx.fillStyle = GRID_NUMBER_10MIN_COLOR;
            ctx.fillText(
              nPlayer_10min,
              col * GRID_WIDTH + GRID_NUMBER_10MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_10MIN_POINT_Y
            );
          } else if (gridStatusArray_10min[i] == GRID_OFFLINE) {
            ctx.fillStyle = "FF000088";
            ctx.fillText(
              "X",
              col * GRID_WIDTH + GRID_NUMBER_10MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_10MIN_POINT_Y
            );
          }
        }

        if (gridStatusArray_20min) {
          if (
            gridStatusArray_20min[i] == GRID_ONLINE &&
            gridData_nPlyaer_20min
          ) {
            const nPlayer_20min = gridData_nPlyaer_20min.nPlayer[i];

            ctx.fillStyle = GRID_NUMBER_20MIN_COLOR;
            ctx.fillText(
              nPlayer_20min,
              col * GRID_WIDTH + GRID_NUMBER_20MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_20MIN_POINT_Y
            );
          } else if (gridStatusArray_20min[i] == GRID_OFFLINE) {
            ctx.fillStyle = "FF000088";
            ctx.fillText(
              "X",
              col * GRID_WIDTH + GRID_NUMBER_20MIN_POINT_X,
              row * GRID_HEIGHT + GRID_NUMBER_20MIN_POINT_Y
            );
          }
        }
      } ////////////////////////////////////////////////////////////////////////
    }
    //ctx.fillText("fofofo", 300, 300);
    /*
    let latestData = jsondata[jsondata.length - 1].data;
    let previousData = jsondata[jsondata.length - 2].data;
    //마지막 업데이트 시간 문자열로 얻기.

    let totalPlayer = 0;

    for (var i = 0; i < 9; i++) {
      for (var j = 0; j < 9; j++) {
        let cellId = i * 9 + j;
        let cellName = String.fromCharCode(65 + j) + (i + 1);
        let cellValue = latestData[cellId];
        let prevValue = previousData[cellId];

        let x = i * GRID_WIDTH + 48;
        let y = j * GRID_HEIGHT + 48;

        //console.log(typeof cellValue);
        if (!isNaN(parseInt(cellValue))) {
          let player_number = parseInt(cellValue);
          totalPlayer += player_number;

          ctx.font = "100px GmarketSansLight";
          ctx.textBaseline = "top";
          ctx.textAlign = "left";

          if (player_number <= 12) ctx.fillStyle = playerColors[player_number];
          else ctx.fillStyle = playerColors[12];
          ctx.fillText(cellValue, x, y);
        } else {
          // 숫자가아닌경우
          ctx.font = "60px sans-serif";
          ctx.textBaseline = "top";
          ctx.textAlign = "center";
          ctx.fillStyle = "#800";
          ctx.fillText("OFFLINE", x + 55, y + 10);
          //offline++;
        }
        //console.log("working");
        //if (isJsonDataUpdated_Ref.current) {
        if (0) {
          // console.log("working");
          if (!isNaN(cellValue) && !isNaN(prevValue)) {
            var change = parseInt(cellValue) - parseInt(prevValue);
            if (change !== 0) {
              // cellid, text, color, x, y, duration,

              // 셀값증가
              if (change > 0) {
                //console.log("griding!!!! ++ ")
                gridingData.push({
                  _cellId: cellId,
                  _text: "+" + change,
                  _color: "#0f0",
                  _x: x + 80,
                  _y: y + 40,
                  _duration: change >= 2 ? 40000 : 20000,
                });

                // Nearding 검사
                const neighbors = getNeighbors(cellId);
                for (let neighborId of neighbors) {
                  if (latestData[neighborId] < previousData[neighborId]) {
                    console.log(
                      "Nearding 감지!!",
                      latestData[neighborId],
                      "<",
                      previousData[neighborId],
                      change
                    );
                    neardingData.push({ _cellID: cellId, _duration: 0 });
                    break; // 조건이 충족되면 추가 검사를 중단
                    //Action()
                  }
                }

                //console.log(cellId, neighbors.length);

                // 셀값 감소
              } else if (change < 0) {
                //console.log("griding!!!! -- ")
                gridingData.push({
                  _cellId: cellId,
                  _text: change,
                  _color: "#f00",
                  _x: x + 85,
                  _y: y + 40,
                  _duration: 20000,
                });
              }
            }
          }
        }

        //var blinkClass = shouldBlink(cellId, currentTime) ? "blink" : ""; // 깜빡임 클래스 적용
        //var changeIndicators = renderChangeIndicators(cellId);

        //   tableContent += `<td class="${colorClass} ${blinkClass}"><div>${cellName}</div><div>${cellValue}${changeIndicators}</div></td>`;
      }
    }
  

    */
  }

  function drawDayTimeInfo(ctx) {
    const serverData = serverDataRef.current;

    ctx.fillStyle = DAYTIME_COLOR;
    ctx.textBaseline = "top";
    ctx.textAlign = "center";

    for (let i = 0; i < NUM_TOTAL_GRID; i++) {
      const col = get_col(i);
      const row = get_row(i);
      const dayTime = serverData.getDayTime();
      const ingameTime = serverData.getInGameTime();
      const ingameTimeinString = secondsToTimeString(ingameTime[i]);

      ctx.font = DAYTIME_TEXT + " GmarketSansLight";
      ctx.fillText(
        "D" + dayTime[i],
        col * GRID_WIDTH + DAYTIME_POINT_X,
        row * GRID_HEIGHT + DAYTIME_POINT_Y
      );
      ctx.font = INGAMETIME_TEXT + " GmarketSansLight";
      ctx.fillText(
        ingameTimeinString,
        col * GRID_WIDTH + DAYTIME_POINT_X,
        row * GRID_HEIGHT + DAYTIME_POINT_Y + 30
      );
    }
  }

  function _calculateShortNeardingColor(nPlayer) {
    if (nPlayer < NEARDING_YELLOW_NUMBER) return NEARDING_SHORT_COLOR;
    else if (nPlayer < NEARDING_ORANGE_NUMBER) return "yellow";
    else if (nPlayer < NEARDING_RED_NUMBER) return "orange";
    else return "red";
  }

  function _drawCurve(ctx, startX, startY, endX, endY, segments = 16) {
    let cpX = (startX + endX) / 2; // Control point X
    let cpY = startY; // Control point Y (startY와 동일한 높이로 설정)

    let lastX1, lastY1, lastX2, lastY2;

    for (let i = 0; i < segments; i++) {
      let t1 = i / segments;
      let t2 = (i + 1) / segments;

      // 첫 번째 세그먼트의 포인트 계산
      let x1 =
        (1 - t1) * (1 - t1) * startX + 2 * (1 - t1) * t1 * cpX + t1 * t1 * endX;
      let y1 =
        (1 - t1) * (1 - t1) * startY + 2 * (1 - t1) * t1 * cpY + t1 * t1 * endY;

      // 두 번째 세그먼트의 포인트 계산
      let x2 =
        (1 - t2) * (1 - t2) * startX + 2 * (1 - t2) * t2 * cpX + t2 * t2 * endX;
      let y2 =
        (1 - t2) * (1 - t2) * startY + 2 * (1 - t2) * t2 * cpY + t2 * t2 * endY;

      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);

      // 마지막 세그먼트의 포인트를 저장
      if (i === segments - 1) {
        lastX1 = x1;
        lastY1 = y1;
        lastX2 = x2;
        lastY2 = y2;
      }
    }

    // 마지막 세그먼트의 단위 벡터 계산   1 에서 2를 빼는 이유는 이함수가 끝점에서 시작점으로 선을 그리는 함수이기때문
    const dx = lastX1 - lastX2;
    const dy = lastY1 - lastY2;
    const length = Math.sqrt(dx * dx + dy * dy);

    // 단위 벡터
    const unitVector = {
      x: dx / length,
      y: dy / length,
    };

    return unitVector;
  }

  function drawGridingLine(ctx) {
    if (isCheckedGridingLine == false) return;

    const serverData = serverDataRef.current;
    const currentTimeStamp = g_currentTime_Ref.current;

    const IncreasedInfo = serverData.getIncreasedGridingInfoBetween(
      currentTimeStamp - 55,
      currentTimeStamp
    );
    const DecreasedInfo = serverData.getDecreasedGridingInfoBetween(
      currentTimeStamp - 55,
      currentTimeStamp
    );

    // 증가된 index들이 저장될 배열
    let increasedIndices = [];
    // 감소된 index들이 저장될 배열
    let decreasedIndices = [];

    for (let i = 0; i < NUM_TOTAL_GRID; i++) {
      const nPlayerDiffInc = IncreasedInfo.totalIncreasedData[i];
      const nPlayerDiffDec = DecreasedInfo.totalDecreasedData[i];

      // 증가된 값이 있는 경우
      if (nPlayerDiffInc) {
        increasedIndices.push(i); // 증가된 index를 배열에 추가
      }
      // 감소된 값이 있는 경우
      if (nPlayerDiffDec) {
        decreasedIndices.push(i); // 감소된 index를 배열에 추가
      }
    }

    /*
    // 20가지 색상을 미리 정의한 배열
    const colors = [
      "#FF573344", "#33FF5744", "#3357FF44", "#FF33A144", "#FF8C3344", 
      "#33FFB844", "#8C33FF44", "#33A1FF44", "#FFC73344", "#57FF3344", 
      "#FF333344", "#33FF9944", "#5733FF44", "#FF573344", "#A133FF44", 
      "#FF33C744", "#33FFC744", "#C733FF44", "#5733FF44", "#33FFC744"
    ];
    */
    const colors = [
      "#FF6347", // 다홍색
      "#9AFF32", // 연두색
      "#FF00FF", // 마젠타색
      "#00FFFF", // 시안색
      "#FF0000",
    ];

    const colors_fade = [
      "#FF634755", // 다홍색
      "#9AFF3255", // 연두색
      "#FF00FF55", // 마젠타색
      "#00FFFF55", // 시안색
      "#FF000055",
    ];

    // decreasedIndices 배열의 크기만큼 반복
    for (let i = 0; i < decreasedIndices.length; i++) {
      let decIndex = decreasedIndices[i];

      // increasedIndices 배열의 크기만큼 반복
      for (let j = 0; j < increasedIndices.length; j++) {
        let incIndex = increasedIndices[j];

        if (incIndex == decIndex) continue; //같은그리드는 선 안그림

        // j 값에 따라 색상을 선택 확인중인 그리드로 오는걸 추적 (이걸 i값으로 바꾸면 다른그리드로 가는걸 추적)
        const colorIndex = j % colors.length;
        ctx.strokeStyle = colors[colorIndex];
        ctx.lineWidth = 4;

        const DiffIncTimeStamp = IncreasedInfo.maxTimeStamp[incIndex];
        const DiffDecTimeStamp = DecreasedInfo.maxTimeStamp[decIndex];
        const DiffTime = DiffIncTimeStamp - DiffDecTimeStamp;

        if (DiffIncTimeStamp < DiffDecTimeStamp) continue;
        if (DiffTime > 15) continue;
        if (DiffTime > 5) {
          // j 값에 따라 색상을 선택 확인중인 그리드로 오는걸 추적 (이걸 i값으로 바꾸면 다른그리드로 가는걸 추적)
          const colorIndex = j % colors_fade.length;
          ctx.strokeStyle = colors_fade[colorIndex];
          ctx.lineWidth = 2;
        }

        const incCol = get_col(incIndex);
        const incRow = get_row(incIndex);
        const decCol = get_col(decIndex);
        const decRow = get_row(decIndex);

        // 선을 그리는 기본 좌표
        const startX = 200 + decCol * GRID_WIDTH;
        const startY = 230 + decRow * GRID_HEIGHT;
        const endX = 80 + incCol * GRID_WIDTH;
        const endY = 60 + incRow * GRID_HEIGHT;

        ctx.beginPath();
        //ctx.moveTo(GRID_NUMBER_POINT_X+50 + decCol*GRID_WIDTH, GRID_NUMBER_POINT_Y+10 + decRow*GRID_HEIGHT);
        //ctx.lineTo(GRID_NUMBER_POINT_X+50 + incCol*GRID_WIDTH, GRID_NUMBER_POINT_Y-30 + incRow*GRID_HEIGHT);
        const unitVector = _drawCurve(ctx, startX, startY, endX, endY);

        // 방향 벡터 (dx, dy) 계산
        const dx = unitVector.x;
        const dy = unitVector.y;

        // 벡터의 길이를 구한 후, 30px로 정규화
        const length = Math.sqrt(dx * dx + dy * dy);
        const unitDx = (dx / length) * 30;
        const unitDy = (dy / length) * 30;

        // 30도 회전한 벡터 계산
        const angle = Math.PI / 7; // 30도 = π/6 라디안

        const rotatedDx1 = unitDx * Math.cos(angle) - unitDy * Math.sin(angle);
        const rotatedDy1 = unitDx * Math.sin(angle) + unitDy * Math.cos(angle);

        const rotatedDx2 =
          unitDx * Math.cos(-angle) - unitDy * Math.sin(-angle);
        const rotatedDy2 =
          unitDx * Math.sin(-angle) + unitDy * Math.cos(-angle);

        // 화살표 양쪽 선 그리기
        ctx.moveTo(endX, endY);
        ctx.lineTo(endX + rotatedDx1, endY + rotatedDy1);
        ctx.moveTo(endX, endY);
        ctx.lineTo(endX + rotatedDx2, endY + rotatedDy2);

        ctx.closePath();

        ctx.stroke();
      }
    }
  }

  function drawNeardingInfo(ctx) {
    if (isCheckedNearding == false) return;

    const serverData = serverDataRef.current;
    const currentTimeStamp = g_currentTime_Ref.current;

    const shortResult = serverData.getNeardingInfoBetween(
      currentTimeStamp - sliderNeardingShort,
      currentTimeStamp
    );
    if (shortResult.totalNeardingData == null) return;
    const longResult = serverData.getNeardingInfoBetween(
      currentTimeStamp - sliderNeardingLong,
      currentTimeStamp
    );

    ctx.textBaseline = "middle";
    ctx.textAlign = "left";

    for (let i = 0; i < NUM_TOTAL_GRID; i++) {
      const col = get_col(i);
      const row = get_row(i);
      const shortNeardingData = shortResult.totalNeardingData[i];
      const shortNeardingMaxTimeStamps = shortResult.maxTimeStamps[i];
      const longNeardingData = longResult.totalNeardingData[i];
      const longNeardingMinTimeStamps = longResult.minTimeStamps[i];

      ////////////////////////////////////////////////////////////////////////////////////////////////
      // Nearding 배경 그리기 배경 배경 배경 배경 배경 배경 배경 배경
      if (shortNeardingData.N != 0 || longNeardingData.N != 0)
        _drawNeardingColor(ctx, NEARDING_FILL_COLOR, col, row, "N");
      if (shortNeardingData.E != 0 || longNeardingData.E != 0)
        _drawNeardingColor(ctx, NEARDING_FILL_COLOR, col, row, "E");
      if (shortNeardingData.S != 0 || longNeardingData.S != 0)
        _drawNeardingColor(ctx, NEARDING_FILL_COLOR, col, row, "S");
      if (shortNeardingData.W != 0 || longNeardingData.W != 0)
        _drawNeardingColor(ctx, NEARDING_FILL_COLOR, col, row, "W");

      ////////////////////////////////////////////////////////////////////////////////////////////////
      // Nearding Short Info 그리기 SHORT SHORT SHORT SHORT SHORT SHORT SHORT SHORT SHORT SRHOT SRHOT
      if (shortNeardingData.N != 0) {
        ctx.fillStyle = _calculateShortNeardingColor(shortNeardingData.N);
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          shortNeardingData.N,
          col * GRID_WIDTH + NEARDING_N_SHORT_POINT_X,
          row * GRID_HEIGHT + NEARDING_N_SHORT_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(shortNeardingMaxTimeStamps.N),
          col * GRID_WIDTH + NEARDING_N_SHORT_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_N_SHORT_POINT_Y
        );
      }

      if (shortNeardingData.E != 0) {
        ctx.fillStyle = _calculateShortNeardingColor(shortNeardingData.E);
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          shortNeardingData.E,
          col * GRID_WIDTH + NEARDING_E_SHORT_POINT_X,
          row * GRID_HEIGHT + NEARDING_E_SHORT_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(shortNeardingMaxTimeStamps.E),
          col * GRID_WIDTH + NEARDING_E_SHORT_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_E_SHORT_POINT_Y
        );
      }

      if (shortNeardingData.S != 0) {
        ctx.fillStyle = _calculateShortNeardingColor(shortNeardingData.S);
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          shortNeardingData.S,
          col * GRID_WIDTH + NEARDING_S_SHORT_POINT_X,
          row * GRID_HEIGHT + NEARDING_S_SHORT_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(shortNeardingMaxTimeStamps.S),
          col * GRID_WIDTH + NEARDING_S_SHORT_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_S_SHORT_POINT_Y
        );
      }
      if (shortNeardingData.W != 0) {
        ctx.fillStyle = _calculateShortNeardingColor(shortNeardingData.W);
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          shortNeardingData.W,
          col * GRID_WIDTH + NEARDING_W_SHORT_POINT_X,
          row * GRID_HEIGHT + NEARDING_W_SHORT_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(shortNeardingMaxTimeStamps.W),
          col * GRID_WIDTH + NEARDING_W_SHORT_POINT_X - 80,
          row * GRID_HEIGHT + NEARDING_W_SHORT_POINT_Y
        );
      }

      ////////////////////////////////////////////////////////////////////////////////////////////////
      // Nearding Long Info 그리기 LONG LONG LONG LONG LONG LONG LONG LONG LONG LONG LONG LONG LONG
      if (longNeardingData.N != 0) {
        ctx.fillStyle = NEARDING_LONG_COLOR;
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          longNeardingData.N,
          col * GRID_WIDTH + NEARDING_N_LONG_POINT_X,
          row * GRID_HEIGHT + NEARDING_N_LONG_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(longNeardingMinTimeStamps.N),
          col * GRID_WIDTH + NEARDING_N_LONG_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_N_LONG_POINT_Y
        );
      }
      if (longNeardingData.E != 0) {
        ctx.fillStyle = NEARDING_LONG_COLOR;
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          longNeardingData.E,
          col * GRID_WIDTH + NEARDING_E_LONG_POINT_X,
          row * GRID_HEIGHT + NEARDING_E_LONG_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(longNeardingMinTimeStamps.E),
          col * GRID_WIDTH + NEARDING_E_LONG_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_E_LONG_POINT_Y
        );
      }

      if (longNeardingData.S != 0) {
        ctx.fillStyle = NEARDING_LONG_COLOR;
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          longNeardingData.S,
          col * GRID_WIDTH + NEARDING_S_LONG_POINT_X,
          row * GRID_HEIGHT + NEARDING_S_LONG_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(longNeardingMinTimeStamps.S),
          col * GRID_WIDTH + NEARDING_S_LONG_POINT_X + 40,
          row * GRID_HEIGHT + NEARDING_S_LONG_POINT_Y
        );
      }
      if (longNeardingData.W != 0) {
        ctx.fillStyle = NEARDING_LONG_COLOR;
        ctx.font = NEARDING_TEXT + " GmarketSansLight";
        ctx.fillText(
          longNeardingData.W,
          col * GRID_WIDTH + NEARDING_W_LONG_POINT_X,
          row * GRID_HEIGHT + NEARDING_W_LONG_POINT_Y
        );
        ctx.font = NEARDING_TIME_TEXT + " GmarketSansLight";
        ctx.fillText(
          timeAgoFromNowInSeconds(longNeardingMinTimeStamps.W),
          col * GRID_WIDTH + NEARDING_W_LONG_POINT_X - 80,
          row * GRID_HEIGHT + NEARDING_W_LONG_POINT_Y
        );
      }
    }
  }

  function _getWindInfo(timeInSeconds) {
    // 현실 18446초 = 인게임 6일, 4661.6 = 18446/4 (바람변경주기)) 131760 (바람변경주기 인게임시간기준)
    const windData = [
      { direction: 12, windPower: 3, startTime: 0, endTime: 65879 }, // day 1 00:00 ~ d
      { direction: 1.5, windPower: 2, startTime: 65880, endTime: 131759 },
      { direction: 3, windPower: 1, startTime: 131760, endTime: 197639 }, // day 2 12:00 ~
      { direction: 4.5, windPower: 2, startTime: 197640, endTime: 263519 },
      { direction: 6, windPower: 3, startTime: 263520, endTime: 329399 }, // day 4 00:00 ~
      { direction: 7.5, windPower: 2, startTime: 329400, endTime: 395279 },
      { direction: 9, windPower: 1, startTime: 395280, endTime: 461159 }, // day 5 12:00 ~
      { direction: 10.5, windPower: 2, startTime: 461160, endTime: 527039 },
      { direction: 12, windPower: 3, startTime: 527040, endTime: Infinity }, // day 7 00:00 ~
    ];

    for (let data of windData) {
      if (timeInSeconds >= data.startTime && timeInSeconds <= data.endTime) {
        return { direction: data.direction, windPower: data.windPower };
      }
    }

    return null; // 유효하지 않은 시간 값에 대한 처리
  }

  function _drawWindText(ctx, ingameWindTime, col, row) {
    ctx.font = WIND_TIME_TEXT + " GmarketSansLight";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    const irlWindTime = (ingameWindTime / INGAME_TIME_MULTIPLIER) % 2305.8;
    const timeRemainingToNewWind = 2305.8 - irlWindTime; // 현실 18446초 = 인게임 6일, 4661.6 = 18446/4 (바람변경주기)) 131760 (바람변경주기 인게임시간기준)

    if (timeRemainingToNewWind > 300) {
      ctx.fillStyle = WIND_TIME_COLOR;
      ctx.fillText(
        secondsToTimeStringMS(timeRemainingToNewWind),
        WIND_TIME_X + col * GRID_WIDTH,
        WIND_TIME_Y + row * GRID_HEIGHT
      );
    } else {
      ctx.fillStyle = "#FF0000";
      ctx.fillText(
        secondsToTimeStringMS(timeRemainingToNewWind),
        WIND_TIME_X + col * GRID_WIDTH,
        WIND_TIME_Y + row * GRID_HEIGHT
      );
    }
  }

  function _rotatePointAroundCenter(x, y, angleInDegrees, cx = 0, cy = 0) {
    // 각도를 라디안으로 변환
    let theta = angleInDegrees * (Math.PI / 180);

    // 점을 중심점으로 이동
    let translatedX = x - cx;
    let translatedY = y - cy;

    // 회전 수식 적용
    let rotatedX =
      translatedX * Math.cos(theta) - translatedY * Math.sin(theta);
    let rotatedY =
      translatedX * Math.sin(theta) + translatedY * Math.cos(theta);

    // 회전된 점을 다시 원래 위치로 이동
    let finalX = rotatedX + cx;
    let finalY = rotatedY + cy;

    return { x: finalX, y: finalY };
  }

  // 10개의 2차원 좌표를 생성
  let wind_coordinates = [
    [
      // 남풍 (북쪽으로 부는 바람) 파워:0 현재미사용
      { x: 2.0, y: -8.0 },
      { x: 0.0, y: -7.0 },
      { x: -8.0, y: -15.0 },
      { x: 0.0, y: -23.0 },
      { x: 8.0, y: -15.0 },
      { x: 2.0, y: -8.0 },
      { x: 2, y: 18 },
      { x: -10, y: 26 },
      { x: 2, y: 18 },
      { x: 2, y: 10 },
      { x: -7, y: 14 },
      { x: 2, y: 10 },
      { x: 2, y: -8 },
    ],
    [
      // 남풍 (북쪽으로 부는 바람) 파워:1
      { x: 2.0, y: -8.0 },
      { x: 0.0, y: -7.0 },
      { x: -8.0, y: -15.0 },
      { x: 0.0, y: -23.0 },
      { x: 8.0, y: -15.0 },
      { x: 2.0, y: -8.0 },
      { x: 2, y: 18 },
      { x: 2, y: 10 },
      { x: -7, y: 14 },
      { x: 2, y: 10 },
      { x: 2, y: -8 },
    ],
    [
      // 남풍 (북쪽으로 부는 바람) 파워:2
      { x: 2.0, y: -8.0 },
      { x: 0.0, y: -7.0 },
      { x: -8.0, y: -15.0 },
      { x: 0.0, y: -23.0 },
      { x: 8.0, y: -15.0 },
      { x: 2.0, y: -8.0 },
      { x: 2, y: 18 },
      { x: -10, y: 26 },
      { x: 2, y: 18 },
      { x: 2, y: 10 },
      { x: -7, y: 14 },
      { x: 2, y: 10 },
      { x: 2, y: -8 },
    ],
    [
      // 남풍 (북쪽으로 부는 바람) 파워:3
      { x: 2.0, y: -8.0 },
      { x: 0.0, y: -7.0 },
      { x: -8.0, y: -15.0 },
      { x: 0.0, y: -23.0 },
      { x: 8.0, y: -15.0 },
      { x: 2.0, y: -8.0 },
      { x: 2, y: 18 },
      { x: -16, y: 18 },
      { x: 2, y: 8 },
      { x: 2, y: 0 },
      { x: -11, y: 8 },
      { x: 2, y: 0 },
      { x: 2, y: -8 },
    ],
  ];

  function _drawWindSymbol(
    ctx,
    ingameWindTime,
    col,
    row,
    stroke = WIND_SYMBOL_STROKE
  ) {
    const windInfo = _getWindInfo(ingameWindTime);
    if (windInfo == null) return;

    const drawPoints = wind_coordinates[windInfo.windPower];

    let rotatedPoints = drawPoints.map((point) =>
      _rotatePointAroundCenter(point.x, point.y, windInfo.direction * 30)
    );

    ctx.beginPath();
    ctx.moveTo(
      rotatedPoints.x + col * GRID_WIDTH,
      rotatedPoints.y + row * GRID_HEIGHT
    );
    for (let i = 1; i < drawPoints.length; i++) {
      ctx.lineTo(
        rotatedPoints[i].x + col * GRID_WIDTH + WIND_SYMBOL_X,
        rotatedPoints[i].y + row * GRID_HEIGHT + WIND_SYMBOL_Y
      );
    }
    ctx.closePath();

    //ctx.fillStyle;
    //ctx.fill();
    if (windInfo.windPower <= 1) ctx.strokeStyle = "#FF2222";
    else if (windInfo.windPower == 2) ctx.strokeStyle = "yellow";
    else if (windInfo.windPower == 3) ctx.strokeStyle = "#22FF22";

    if (stroke) {
      ctx.lineWidth = WIND_SYMBOL_STROKE_WIDTH;
      ctx.stroke();
    }
  }

  function drawWindpowerInfo(ctx) {
    if (isCheckedWindPower == false) return;

    const serverData = serverDataRef.current;
    const ingameWindTime = serverData.getWindTime();

    for (let i = 0; i < NUM_TOTAL_GRID; i++) {
      const col = get_col(i);
      const row = get_row(i);

      _drawWindText(ctx, ingameWindTime[i], col, row);
      _drawWindSymbol(ctx, ingameWindTime[i], col, row);
    }
  }

  function initBG() {
    ctxBG.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

    // 배경과 보스아이콘
    BGImages.forEach((imgInfo) => {
      if (imgInfo.loaded) {
        /*if (imgInfo.imageType == "bg" && isCheckedIslands == false) {
          ctxBG.globalAlpha = imgInfo.globalAlpha;
          ctxBG.drawImage(
            imgInfo.image,
            imgInfo.x,
            imgInfo.y,
            256 * 6,
            256 * 6,
            0,
            0,
            CANVAS_WIDTH,
            CANVAS_HEIGHT
          );*/
        if (imgInfo.imageType == "bg" && isCheckedIslands == false) {
          ctxBG.globalAlpha = imgInfo.globalAlpha;
          ctxBG.fillStyle = "#003377";
          ctxBG.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
        } else if (imgInfo.imageType == "TradeWind" && isCheckedTradeWind) {
          ctxBG.globalAlpha = imgInfo.globalAlpha;
          ctxBG.drawImage(
            imgInfo.image,
            imgInfo.x,
            imgInfo.y,
            1864,
            1864,
            0,
            0,
            CANVAS_WIDTH,
            CANVAS_HEIGHT
          );
        } else if (imgInfo.imageType == "Islands" && isCheckedIslands) {
          ctxBG.globalAlpha = imgInfo.globalAlpha;
          ctxBG.drawImage(
            imgInfo.image,
            imgInfo.x,
            imgInfo.y,
            1864,
            1864,
            0,
            0,
            CANVAS_WIDTH,
            CANVAS_HEIGHT
          );
        } else if (imgInfo.imageType == "BossIcon") {
          if (isCheckedBoss) {
            ctxBG.globalAlpha = imgInfo.globalAlpha;
            ctxBG.drawImage(
              imgInfo.image,
              imgInfo.x,
              imgInfo.y,
              imgInfo.width,
              imgInfo.height
            );
          } else {
            //보스체크 안되어있으면 안그림
          }
        }
      }
    });

    // 그리드 이름 출력
    ctxBG.globalAlpha = 1.0;
    ctxBG.font = GRID_NAME_TEXT + " GmarketSansMedium";
    ctxBG.textBaseline = "top";
    ctxBG.textAlign = "left";

    ctxBG.fillStyle = GRID_NAME_COLOR;
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        ctxBG.fillText(
          String.fromCharCode(i + 65) + (j + 1),
          i * GRID_WIDTH + GRID_NAME_POINT_X,
          j * GRID_HEIGHT + GRID_NAME_POINT_Y
        );
      }
    }
  }

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.canvasContainer}>
          <div className={styles.topBox}>
            <input
              type="checkbox"
              id="CB_bossIcon"
              checked={isCheckedBoss}
              onChange={CB_bossIcon_Change}
            />
            <label htmlFor="CB_bossIcon">Boss</label>
            <input
              type="checkbox"
              id="CB_tradewind"
              checked={isCheckedTradeWind}
              onChange={CB_tradewind_Change}
            />
            <label htmlFor="CB_tradewind">Trade Wind</label>
            <input
              type="checkbox"
              id="CB_islands"
              checked={isCheckedIslands}
              onChange={CB_islands_Change}
            />
            <label htmlFor="CB_islands">Islands</label>
            <input
              type="checkbox"
              id="CB_nearding"
              checked={isCheckedNearding}
              onChange={CB_nearding_Change}
            />
            <label htmlFor="CB_nearding">Nearding</label>
            <input
              type="checkbox"
              id="CB_prevGridNumber"
              checked={isCheckedPrevGridNumber}
              onChange={CB_prevGridNumber_Change}
            />
            <label htmlFor="CB_prevGridNumber">PrevGrid</label>
            <input
              type="checkbox"
              id="CB_gridingLine"
              checked={isCheckedGridingLine}
              onChange={CB_gridingLine_Change}
            />
            <label htmlFor="CB_gridingLine">GridingLine</label>
            <input
              type="checkbox"
              id="CB_windPower"
              checked={isCheckedWindPower}
              onChange={CB_windPower_Change}
            />
            <label htmlFor="CB_windPower">Wind</label>
            <br />
            <br />
            Nearding Info Long Duration (seconds)
            <Slider
              aria-labelledby="continuous-slider"
              value={sliderNeardingLong} // 슬라이더 값 설정
              onChange={handleSliderNeardingLongChange} // 값 변경 시 호출될 함수
              valueLabelDisplay="auto"
              step={600}
              marks
              min={600}
              max={7200}
              defaultValue={600}
              sx={{
                display: "flex",
                width: "100%", // 슬라이더 너비를 절반으로 줄임
                height: "10px",
                color: "#004400", // 슬라이더 색상을 녹색으로 변경
                "& .MuiSlider-thumb": {
                  color: "green", // 슬라이더 썸(손잡이) 색상 변경
                },
              }}
            />
            <br />
            Nearding Info Short Duration (seconds)
            <Slider
              aria-labelledby="continuous-slider"
              value={sliderNeardingShort} // 슬라이더 값 설정
              onChange={handleSliderNeardingShortChange} // 값 변경 시 호출될 함수
              valueLabelDisplay="auto"
              step={60}
              marks
              min={120}
              max={600}
              defaultValue={180}
              sx={{
                display: "flex",
                width: "100%", // 슬라이더 너비를 절반으로 줄임
                height: "10px",
                color: "#004400", // 슬라이더 색상을 녹색으로 변경
                "& .MuiSlider-thumb": {
                  color: "green", // 슬라이더 썸(손잡이) 색상 변경
                },
              }}
            />
            <br />
            <br />
            
            <div>Server Updated: {LastServerUpdatedAt}</div>
            <br />
            <div>
              Total Active Player: {TotalPlayer}
            </div>
          </div>

          <Canvas
            draw={draw}
            width="2304"
            height="2304"
            className={styles.canvas}
          />
        </div>
        
      </div>
      <div className={styles.pageBottomADContainer}></div>
    </>
  );
}

export default Atlasgrid;
