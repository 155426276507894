
import {BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';
import Navbar from "./pages/Navbar";
import Official from "./pages/official";
import Atlaswars from "./pages/atlaswars";
import About from "./pages/About";
import GoogleAD from "./components/GoogleAD";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
    <Routes>
      <Route path="/atlas/" element={<Official/>}/>
      <Route path="/atlaswars/" element={<Atlaswars/>}/>
      <Route path="/about/" element={<About/>}/>
    </Routes>
      <GoogleAD />
    </BrowserRouter>

  );
}

export default App;
