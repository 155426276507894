import React, { useEffect } from 'react';

function GoogleAD() {
  useEffect(() => {
    // Google AdSense 스크립트를 동적으로 추가
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6161705801434448';
    script.async = true;
    script.crossOrigin = 'anonymous'; // crossorigin 속성 추가
    document.head.appendChild(script);

    // 광고 요소를 초기화
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="ad-container">
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-6161705801434448"  // 본인의 AdSense Publisher ID
        data-ad-slot="2479968575"  // 광고 단위의 ID
        data-ad-format="auto"
        data-full-width-responsive="true" />
    </div>
  );
}

export default GoogleAD;
