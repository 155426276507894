// Default Export
export default class atlasServerData {
    constructor() {
        this.num_total_grid = 36;

        this.lastServerResponseTimeStamp = 0;   // 서버에서 받아온값으로 그대로사용 # No Processing // float 값
        this.lastDataTimeStamp = 0;             // 서버에서 받아온값으로 그대로사용 # No Processing // float 값
        this.nPlayer = 0;                       // 서버에서 받아온값으로 그대로사용 # No Processing // int 값

        this.nPlayer_10min = 0;                 // 매 프레임 n분전 nPlayer 값을 listGridnPlayer 부터 추출. // int값
        this.nPlayer_20min = 0;                 // 매 프레임 n분전 nPlayer 값을 listGridnPlayer 부터 추출. // int값
        this.nPlayer_5min = 0;                  // 매 프레임 n분전 nPlayer 값을 listGridnPlayer 부터 추출. // int값

        this.DayTime = Array(this.num_total_grid).fill(0);                      // 서버에서 받아온값으로 그대로사용 # No Processing // int형 1차원배열
        this.DayTimeTimeStamp = Array(this.num_total_grid).fill(0);             // 서버에서 받아온값으로 그대로사용 # No Processing // float형 1차원 배열
        
        this.ingameTime = [];                   // 현재시간 - DayTimeTimeStamp 으로 매프레임 계산할건데 str으로 저장해서 그대로 뿌려줄지
                                                // 아니면 float값 배열로 저장했다가 drawing함수내에서 부분별로 float to str 변환할지
                                                // 뭐가 좋을까?

        this.listGridStatus = [];               // 데이터 저장을 위한 배열. 배열의 크기는 총 그리드(서버) 수
                                                // 하나의 데이터에는 timeStamp (float) 값과 서버상태를 나타내는 (int) 값의 배열로 이루어짐 (이중배열))

        
        this.listGridnPlayers = [];              // 데이터 저장을 위한 배열. 배열의 크기는 총 그리드(서버) 수
                                                // 하나의 데이터에는 timeStamp값과 해당시간의 모든그리드의 접속자수를 배열로받음 (이중배열)
                                                // 그래서 이걸 dataType을 새로만들지 튜플?같은걸 사용할지 고민됨.
                                                // timeStamp는 float값 접속자수는 int값

        this.listNeardingInfo = [];             // 데이터 저장을 위한 배열. 배열의 크기는 총 그리드(서버) 수
                                                // 하나의 데이터에는 timeStamp값과 Nearding 데이터로 이루어짐
                                                // Nearding Data는 str, int 값이 pair로 저장되어있음.
                                
        this.listGridingInfo = [];             // 데이터 저장을 위한 배열. 배열의 크기는 총 그리드(서버) 수
                                                // 하나의 데이터에는 timeStamp값과 Nearding 데이터로 이루어짐
                                                // Nearding Data는 str, int 값이 pair로 저장되어있음.
    
        // 데이터 유지 시간  120분 * 60초 = 최신 2시간 데이터 유지 새로고침하면 초기화되고 서버로부터 받아오는데이터는 서버 세팅에 따름
        this.duration = 14400; // 14400 = 4시간
        this.offlineDuration = 604800; // 604800 = 7일

        // 게임내 시간을 계산하기위한 상수
        this.IGTimeMultiplier = 28.5714; // 인게임 1분 = 현실 2.1초 60/2.1 = 28.5714
        this.aDayinSeconds = 86400;
        this.isReady = false;
    }

    getReady() {
        return this.isReady;
    }
    setReady( value ) {
        this.isReady = value;
    }

    // 매 프레임마다 호출되는 함수: 화면에 보여줄 데이터는 매 프레임마다 갱신해야한다.
    // [중요!!] 이 구조체에 필요한데이터를 먼저 업데이트한 후 Update()함수 호출
    Update(currentTime, deltaTime) {

        // 10분전 20분전 30분전 접속자수 갱신
        const gridData_nPlyaer_5min = this.getLatestDataBefore(currentTime - 300);
        const gridData_nPlyaer_10min = this.getLatestDataBefore(currentTime - 600);
        const gridData_nPlyaer_20min = this.getLatestDataBefore(currentTime - 1200);

        if(gridData_nPlyaer_5min != null ) 
            this.nPlayer_5min = this.get_nPlayerFromList(gridData_nPlyaer_5min.nPlayer);
        if(gridData_nPlyaer_10min != null ) 
            this.nPlayer_10min = this.get_nPlayerFromList(gridData_nPlyaer_10min.nPlayer);
        if(gridData_nPlyaer_20min != null ) 
            this.nPlayer_20min = this.get_nPlayerFromList(gridData_nPlyaer_20min.nPlayer);
        
        

        //[인게임 시간] 시간차를 계산후 24:00 을 넘어서면 24:00으로 제한 
        this.ingameTime = this.DayTimeTimeStamp.map(value => 
            //Math.min( currentTime - value * this.IGTimeMultiplier, this.aDayinSeconds)
            Math.min( (currentTime - value > 0? currentTime - value : 0) * this.IGTimeMultiplier, this.aDayinSeconds)
        );

        //[Nearding Time]

    }

    // 오래된 데이터를 정리하는 메서드
    cleanOldData() {
        const currentTime = Date.now() / 1000;

        this.listGridnPlayers = this.listGridnPlayers.filter(item => currentTime - item.timestamp <= this.duration);

        this.listGridStatus = this.listGridStatus.filter(item => currentTime - item.timestamp <= this.offlineDuration); // 서버 오프라인정보는 최대 7주일까지 보관 

        this.listNeardingInfo = this.listNeardingInfo.filter(item => currentTime - item.timestamp <= this.duration);

        this.listGridingInfo = this.listGridingInfo.filter(item => currentTime - item.timestamp <= this.duration);
    }

    setDayTime(dayTime) { 
        for(let i = 0; i < this.DayTime.length; i++)
            this.DayTime[i] = dayTime[i] > 0 ? dayTime[i] : this.DayTime[i];
    }
    getDayTime() { return this.DayTime; }

    setDayTimeTimeStamp(dayTimeTimeStamp) { 
        this.DayTimeTimeStamp = dayTimeTimeStamp;
    }
    getDayTimeTimeStamp() { return this.DayTimeTimeStamp; }

    getWindTime() {
        let result = [];
    
        //console.log(this.ingameTime);
        // 두 배열의 길이가 같다고 가정
        for (let i = 0; i < this.DayTime.length; i++) {
            let dayValue = ((this.DayTime[i] - 1 ) % 6);
            //console.log(dayValue);
            let windTime = this.ingameTime[i] + (dayValue) * 86400;
            result.push(windTime);
        }
        //console.log(result);
        return result; // 배열로 결과 리턴
    }

    getInGameTime() { return this.ingameTime; }

    // 적용완료
    addGridnPlayerData(timestamp, nPlayerArray) {
        this.listGridnPlayers.push({ timestamp, nPlayer: nPlayerArray });
        //this.cleanOldData(); // 외부에서 호출로 변경
    }

    getLastGridnPlayerData() {
        if (this.listGridnPlayers.length > 0) {
            return this.listGridnPlayers[this.listGridnPlayers.length - 1];
        }
        return null; // 배열이 비어있을 경우 null 반환
    }

    getLatestDataBefore(timeStamp) {
        // timestamp값보다 작은 데이터만 필터링
        const filteredData = this.listGridnPlayers.filter(item => item.timestamp < timeStamp);
        // 필터링된 데이터가 없다면 null 리턴
        if (filteredData.length === 0) { return null; }

        // 가장 최신 데이터를 찾기 위해 최대값을 구함
        const latestData = filteredData.reduce((prev, current) => {
            return (prev.timestamp > current.timestamp) ? prev : current;
        });

        return latestData;
    }

    get_nPlayerFromList( nPlayers ) {
        return nPlayers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    addGridStatusData(timestamp, statusArray) {
        this.listGridStatus.push({ timestamp, statusArray: statusArray });
        //this.cleanOldData(); // 외부에서 호출로 변경
    }
    getLastGridStatusData() {
        if (this.listGridStatus.length === 0) 
            return null;
        
        const lastStatusArray = this.listGridStatus[this.listGridStatus.length - 1].statusArray;
        const lastTimestamps = Array(lastStatusArray.length).fill(0);

       
        // 뒤에서부터 순차적으로 탐색하며 상태가 바뀐 시점을 찾음
        for (let i = this.listGridStatus.length - 1; i >= 0; i--) {
            const { timestamp, statusArray } = this.listGridStatus[i];

            statusArray.forEach((status, index) => {
                // 마지막 상태와 현재 상태가 다르다면, 그 시점이 마지막 변경 시점
                if (lastTimestamps[index] === 0 && status !== lastStatusArray[index]) {
                    lastTimestamps[index] = timestamp;
                }
            });

            // 모든 서버의 변경 시점을 다 찾았다면, 반복 종료
            if (lastTimestamps.every(ts => ts !== 0)) {
                break;
            }
        }

        // 아직 상태가 변경된 적이 없는 서버는 마지막 상태의 타임스탬프를 기록
        lastTimestamps.forEach((ts, index) => {
            if (ts === 0) {
                lastTimestamps[index] = this.listGridStatus[0].timestamp;
            }
        });


        return {timeStampArray: lastTimestamps, gridStatusArray: this.listGridStatus[this.listGridStatus.length - 1].statusArray};
    }

    getLatestGridStatusDataBefore(timeStamp) {

        if (this.listGridStatus.length === 0) 
            return null;
        
        // timestamp값보다 작은 데이터만 필터링
        const filteredData = this.listGridStatus.filter(item => item.timestamp < timeStamp);
        // 필터링된 데이터가 없다면 null 리턴
        if (filteredData.length === 0) { return null; }

         // 가장 최신 데이터를 찾기 위해 최대값을 구함
         const latestGridStatusData = filteredData.reduce((prev, current) => {
            return (prev.timestamp > current.timestamp) ? prev : current;
        });

        return latestGridStatusData.statusArray;

    }



    addNeardingInfoData(timestamp, neardingData) {
        if( neardingData.every(value => Array.isArray(value) && value.length === 0) ) return;

        this.listNeardingInfo.push({ timestamp, neardingData });
        //console.log(this.listNeardingInfo);
        //this.cleanOldData(); // 외부에서 호출로 변경
    }
    getLastNeardingInfoData() {
        if (this.listNeardingInfo.length > 0) {
            return this.listNeardingInfo[this.listNeardingInfo.length - 1];
        }
        return null; // 배열이 비어있을 경우 null 반환
    }
    getNeardingInfoBetween(startTimestamp, endTimestamp) {
        // 결과를 저장할 구조체 초기화: 36개의 고정 배열, 각 배열은 키가 'N', 'E', 'S', 'W'인 객체
        let totalNeardingData = Array(this.num_total_grid).fill(null).map(() => ({
            N: 0,
            E: 0,
            S: 0,
            W: 0
        }));

        // 각 인덱스별 minTimeStamp와 maxTimeStamp를 this.num_total_grid 크기의 배열로 초기화
        let minTimeStamps = Array(this.num_total_grid).fill(null).map(() => ({
            N: Infinity,
            E: Infinity,
            S: Infinity,
            W: Infinity
        }));
        let maxTimeStamps = Array(this.num_total_grid).fill(null).map(() => ({
            N: -Infinity,
            E: -Infinity,
            S: -Infinity,
            W: -Infinity
        }));
    
        // 리스트를 순회하면서 startTimestamp와 endTimestamp 사이의 항목들만 처리
        for (let info of this.listNeardingInfo) {

            if (info.timestamp > startTimestamp && info.timestamp < endTimestamp) {
                let neardingData = info.neardingData;
    
                // 36개의 고정 배열 순회
                for (let i = 0; i < neardingData.length; i++) {
                    let currentArray = neardingData[i];
    
                    // 해당 인덱스에 있는 가변 배열 순회
                    for (let j = 0; j < currentArray.length; j++) {
                        let currentItem = currentArray[j];
                        let direction = currentItem.key.slice(-1); // 'string'의 마지막 문자 추출 ('N', 'E', 'S', 'W')
    
                        // 해당 방향으로 합산
                        if (totalNeardingData[i][direction] !== undefined) {
                            totalNeardingData[i][direction] += currentItem.value;

                            // 각 인덱스별 minTimeStamp와 maxTimeStamp 업데이트
                            if (info.timestamp < minTimeStamps[i][direction]) {
                                minTimeStamps[i][direction] = info.timestamp;
                            }
                            if (info.timestamp > maxTimeStamps[i][direction]) {
                                maxTimeStamps[i][direction] = info.timestamp;
                            }
                        }
                    }
                }
            }
        }
    
        return {totalNeardingData, minTimeStamps, maxTimeStamps};
    }

    addGridingInfoData(timestamp, gridingData) {
        if (!Array.isArray(gridingData) || gridingData.every(value => value === 0)) return;
        this.listGridingInfo.push({ timestamp, gridingData });
        //console.log(this.listGridingInfo);
        //this.cleanOldData(); // 외부에서 호출로 변경
    }
    getLastGridingInfoData() {
        if (this.listGridingInfo.length > 0) {
            return this.listGridingInfo[this.listGridingInfo.length - 1];
        }
        return null; // 배열이 비어있을 경우 null 반환
    }

    getGridingInfoBetween(startTimestamp, endTimestamp) {
        // 결과를 저장할 구조체 초기화: 36개의 고정 배열, 각 배열은 키가 'N', 'E', 'S', 'W'인 객체
        let totalGridingData = Array(this.num_total_grid).fill(0);

        // 각 인덱스별 minTimeStamp와 maxTimeStamp를 this.num_total_grid 크기의 배열로 초기화
        let minTimeStamp = Array(this.num_total_grid).fill(0);
        let maxTimeStamp = Array(this.num_total_grid).fill(0);
    
        // 리스트를 순회하면서 startTimestamp와 endTimestamp 사이의 항목들만 처리
        for (let info of this.listGridingInfo) {

            if (info.timestamp > startTimestamp && info.timestamp < endTimestamp) {
                let gridingData = info.gridingData;
    
                // 36개의 고정 배열 순회
                for (let i = 0; i < gridingData.length; i++) {
                    let griding_value = gridingData[i];
    
                    // 해당 방향으로 합산
                    if (totalGridingData[i] !== undefined) {
                        totalGridingData[i] += griding_value;

                        // 각 인덱스별 minTimeStamp와 maxTimeStamp 업데이트
                        if(info.timestamp < minTimeStamp[i]) {
                            minTimeStamp[i] = info.timestamp;
                        }
                        if(info.timestamp > maxTimeStamp[i]) {
                            maxTimeStamp[i] = info.timestamp;
                        }
                    }
                }
            }
        }
        return {totalGridingData, minTimeStamp, maxTimeStamp};
    }
    getIncreasedGridingInfoBetween(startTimestamp, endTimestamp) {
        // 결과를 저장할 구조체 초기화: 36개의 고정 배열, 각 배열은 키가 'N', 'E', 'S', 'W'인 객체
        let totalIncreasedData = Array(this.num_total_grid).fill(0);

        // 각 인덱스별 minTimeStamp와 maxTimeStamp를 this.num_total_grid 크기의 배열로 초기화
        let minTimeStamp = Array(this.num_total_grid).fill(Infinity);
        let maxTimeStamp = Array(this.num_total_grid).fill(-Infinity);
    
        // 리스트를 순회하면서 startTimestamp와 endTimestamp 사이의 항목들만 처리
        for (let info of this.listGridingInfo) {

            if (info.timestamp > startTimestamp && info.timestamp < endTimestamp) {
                let gridingData = info.gridingData;
    
                // 36개의 고정 배열 순회
                for (let i = 0; i < gridingData.length; i++) {
                    let griding_value = gridingData[i];
    
                    // 해당 방향으로 합산
                    if (totalIncreasedData[i] !== undefined) {
                        if( griding_value > 0 ){
                            totalIncreasedData[i] += griding_value;

                            // 각 인덱스별 minTimeStamp와 maxTimeStamp 업데이트
                            if(info.timestamp < minTimeStamp[i]) {
                                minTimeStamp[i] = info.timestamp;
                            }
                            if(info.timestamp > maxTimeStamp[i]) {
                             maxTimeStamp[i] = info.timestamp;
                            }
                        }
                    }
                }
            }
        }
        return {totalIncreasedData, minTimeStamp, maxTimeStamp};
    }
    getDecreasedGridingInfoBetween(startTimestamp, endTimestamp) {
        // 결과를 저장할 구조체 초기화: 36개의 고정 배열, 각 배열은 키가 'N', 'E', 'S', 'W'인 객체
        let totalDecreasedData = Array(this.num_total_grid).fill(0);

        // 각 인덱스별 minTimeStamp와 maxTimeStamp를 this.num_total_grid 크기의 배열로 초기화
        let minTimeStamp = Array(this.num_total_grid).fill(Infinity);
        let maxTimeStamp = Array(this.num_total_grid).fill(-Infinity);
    
        // 리스트를 순회하면서 startTimestamp와 endTimestamp 사이의 항목들만 처리
        for (let info of this.listGridingInfo) {

            if (info.timestamp > startTimestamp && info.timestamp < endTimestamp) {
                let gridingData = info.gridingData;
    
                // 36개의 고정 배열 순회
                for (let i = 0; i < gridingData.length; i++) {
                    let griding_value = gridingData[i];
    
                    // 해당 방향으로 합산
                    if (totalDecreasedData[i] !== undefined) {
                        if( griding_value < 0 ){
                            totalDecreasedData[i] += griding_value;

                            // 각 인덱스별 minTimeStamp와 maxTimeStamp 업데이트
                            if(info.timestamp < minTimeStamp[i]) {
                                minTimeStamp[i] = info.timestamp;
                            }
                            if(info.timestamp > maxTimeStamp[i]) {
                             maxTimeStamp[i] = info.timestamp;
                            }
                        }
                    }
                }
            }
        }
        return {totalDecreasedData, minTimeStamp, maxTimeStamp};
    }
}