/* eslint-disable */
import React, { useState } from 'react';

import styles from "./Atlasgrid.module.css";

function About() {
    return (
      
      <>
        <div className={styles.aboutPage}>
          <div><img src="https://blog.kggstudio.com/wp-content/uploads/2024/09/info.jpg" alt="IMAGE: how to read information"
          style={{ width: '400px', height: '400px' }} /></div>
          <br />
          <br />
          <div style={{ fontSize: '24px', color: '#0F0' }}> Ahoy Pathfinders!!</div>
          <br />
          <div> This website is developed to provide information about Steam & XBOX game <a href="https://store.steampowered.com/app/834910/ATLAS/" target="_blank" 
            rel="noopener noreferrer"
            style={{
              color: 'GREEN',             // 링크 텍스트 색상 상속
              textDecoration: 'none'        // 링크 밑줄 제거
            }}>[ATLAS]</a> </div>
            <div>Official server is currnetly unavailble, so people who want to play ATLAS PVP server, they are gathered to <a href="https://discord.gg/EqQrkbsb" target="_blank" 
            rel="noopener noreferrer"
            style={{
              color: 'GREEN',             // 링크 텍스트 색상 상속
              textDecoration: 'none'        // 링크 밑줄 제거
            }}>[Atlas Wars]</a> private server.</div>
            <div>Therefore, I expanded this website to cover Atlas Wars server too.</div>
            <div>Also, I will keep adding Atlas related stuff on this website. I am currently working tame calculator, horse is done, to help in taming & breeding for your game play.</div>
            <div>If you have information such as data table of animal stats, please inform me. I will finish all the rest animals.</div>
            <br />
            <div>Huge appreciations for reading this message</div>
            <br />
            <div>If you have any enquires, please contact to the Discord Profile below.</div>

          <br/>
          <div> Web Developer: 팀탐 TimTam </div>
          <br/>

          <a 
            href="https://discord.com/users/849847366126141461" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{
              display: 'flex',             // Flexbox 사용
              alignItems: 'center',         // 수직 가운데 정렬
              color: 'inherit',             // 링크 텍스트 색상 상속
              textDecoration: 'none'        // 링크 밑줄 제거
            }}
          >
            <img src="https://support.discord.com/hc/theming_assets/01HZPN9XBJQZSPXWYYVY76TBEE"  alt="TimTam"
            style={{ 
              width: 'auto',              // 이미지 크기 조정
              height: '15px', 
              marginRight: '8px'          // 이미지와 텍스트 간격 조정
            }}
            /> 팀탐 TimTam
          </a>
          


        </div>
    </>
    )
}

export default About;