//import React, { useState } from 'react';

/*import styles from "./Atlasgrid.module.css";
<div className={styles.aboutPage}>
         test


        </div
*/

import React, { useState, useEffect } from "react";
import styled from "styled-components";

// 동물 데이터 배열
const animals = [
  {
    name: "Horse",
    image: "./images/Horse.webp",
    stats: {
      Health: { base: 225, increment: 33.75 },
      Stamina: { base: 580, increment: 58 },
      Food: { base: 1350, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 750, increment: 15 },
    },
    description: "It is a HoRsE!",
    tame_method: "bola",
    preferred_food: "Sugar Cane (best) > Crop > Wild Crop > Fruit, Berry",
  },
  {
    name: "Bear",
    image: "./images/Bear.webp",
    stats: {
      Health: { base: 262.5, increment: 39.39 },
      Stamina: { base: 500, increment: 50 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 270, increment: 27 },
      Weight: { base: 950, increment: 19 },
    },
    description: "It is a HoRsE!",
    tame_method: "bola",
    preferred_food: "Honey > Crops > Wild Vege > Fruit, Berry ",
  },
  {
    name: "Cat",
    image: "./images/Cat.webp",
    stats: {
      Health: { base: 75, increment: 33.75 },
      Stamina: { base: 500, increment: 50 },
      Food: { base: 3000, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 175, increment: 17.5 },
      Weight: { base: 25, increment: 15 },
    },
    description: "Rat, Fish Meat, Keratin",
    tame_method: "Passive",
    preferred_food: "Milk > Prime Shark > Prime Fish > Fish",
  },
  {
    name: "Chicken",
    image: "./images/Chicken.webp",
    stats: {
      Health: { base: 35, increment: 33.75 },
      Stamina: { base: 200, increment: 20 },
      Food: { base: 600, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 175, increment: 17.5 },
      Weight: { base: 25, increment: 15 },
    },
    description: "Female provides eggs for cooking",
    tame_method: "Passive",
    preferred_food: "Berry, Fruit",
  },
  {
    name: "Cow/Bull",
    image: "./images/Bull.webp",
    stats: {
      Health: { base: 131.325, increment: 15 },
      Stamina: { base: 280, increment: 28 },
      Food: { base: 1200, increment: 10 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 650, increment: 30 },
    },
    description: "Now can carry cargo saddle",
    tame_method: "Passive/Bola",
    preferred_food: "Rushes, Fronds > Crop > Fruit, Berry",
  },
  {
    name: "Crab",
    image: "./images/Crab.webp",
    stats: {
      Health: { base: 300, increment: 15 },
      Stamina: { base: 450, increment: 45 },
      Food: { base: 2000, increment: 200 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: Infinity, increment: Infinity },
      Weight: { base: 650, increment: 10 },
    },
    description: "only eats Rotten Meat",
    tame_method: "stun",
    preferred_food: "Squid Innards",
  },
  {
    name: "Crocodile",
    image: "./images/Crocodile.webp",
    stats: {
      Health: { base: 350, increment: 1 },
      Stamina: { base: 450, increment: 45 },
      Food: { base: 1500, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: Infinity, increment: Infinity },
      Weight: { base: 300, increment: 1 },
    },
    description: "No saddle required",
    tame_method: "Bola",
    preferred_food:
      "Prime Shark > Marrow > Prime Meat, Prime Fish > Meat, Fish",
  },
  {
    name: "Crow",
    image: "./images/Crow.webp",
    stats: {
      Health: { base: 45, increment: 5 },
      Stamina: { base: 100, increment: 10 },
      Food: { base: 900, increment: 90 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 15, increment: 3 },
    },
    description: "INT buff on shoulder",
    tame_method: "Bola",
    preferred_food: "Earthworm",
  },
  {
    name: "Cyclops",
    image: "./images/Dolphin.webp",
    stats: {
      Health: { base: 2310, increment: 1 },
      Stamina: { base: 400, increment: 40 },
      Food: { base: 2250, increment: 225 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 1850, increment: 1 },
    },
    description: "Requires Token to tame",
    tame_method: "Token",
    preferred_food: "Token",
  },
  {
    name: "Dolphin",
    image: "./images/Dolphin.webp",
    stats: {
      Health: { base: 150, increment: 1 },
      Stamina: { base: 300, increment: 30 },
      Food: { base: 1000, increment: 100 },
      Damage: { base: 114, increment: 4.45 },
      Oxygen: { base: Infinity, increment: Infinity },
      Weight: { base: 250, increment: 1 },
    },
    description: "INT buf by jumping",
    tame_method: "Passive",
    preferred_food: "Squid Tentacle > Prime Shark Meat",
  },
  {
    name: "Elephant",
    image: "./images/Elephant.webp",
    stats: {
      Health: { base: 325.1, increment: 48.76 },
      Stamina: { base: 330, increment: 33 },
      Food: { base: 5000, increment: 500 },
      Damage: { base: 114, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 2000, increment: 40 },
    },
    description:
      "Farm wood and berries well. Puckles can be placed on platform.",
    tame_method: "Bola",
    preferred_food: "Wheat > Crop > Wild Vege > Fruit",
  },
  {
    name: "Fire Elemental",
    image: "./images/Fire_Elemental.webp",
    stats: {
      Health: { base: 4125, increment: 1 },
      Stamina: { base: 500, increment: 50 },
      Food: { base: 2500, increment: 250 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 2150, increment: 1 },
    },
    description: "Requires Token to tame",
    tame_method: "Token",
    preferred_food: "Token",
  },
  {
    name: "Giraffe",
    image: "./images/Giraffe.webp",
    stats: {
      Health: { base: 287.5, increment: 43.5 },
      Stamina: { base: 440, increment: 44 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 1540, increment: 30.8 },
    },
    description: "Farm thatch and berries well.",
    tame_method: "bola",
    preferred_food: "Beet > Crop > Wild Vege > Fruit",
  },
  {
    name: "Gorgon",
    image: "./images/Gorgon.webp",
    stats: {
      Health: { base: 250, increment: 1 },
      Stamina: { base: 150, increment: 15 },
      Food: { base: 1200, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 600, increment: 1 },
    },
    description: "Freezes who look at themselves",
    tame_method: "Not Possible",
    preferred_food: "",
  },
  {
    name: "Lion",
    image: "./images/Lion.webp",
    stats: {
      Health: { base: 162.5, increment: 1 },
      Stamina: { base: 240, increment: 24 },
      Food: { base: 1200, increment: 120 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 220, increment: 1 },
    },
    description: "Can bite small creatures",
    tame_method: "Bola",
    preferred_food:
      "Marrow > Prime Meat,Fish > Worms > Crustacean > Meat, Fish",
  },
  {
    name: "Monkey",
    image: "./images/Monkey.webp",
    stats: {
      Health: { base: 48.8, increment: 1 },
      Stamina: { base: 100, increment: 10 },
      Food: { base: 600, increment: 60 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 40, increment: 1 },
    },
    description:
      "Slow enemy with its shit, and recover HP of the one who carries it.",
    tame_method: "Passive",
    preferred_food: "Berry, Fruit",
  },
  {
    name: "Olfend",
    image: "./images/Olfend.png",
    stats: {
      Health: { base: 200, increment: 1 },
      Stamina: { base: 380, increment: 38 },
      Food: { base: 1500, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 750, increment: 1 },
    },
    description: "Store water and water ",
    tame_method: "Bola",
    preferred_food: "Cactus > Crop > Wild Vege > Fruit, Berry",
  },
  {
    name: "Ostrich",
    image: "./images/Ostrich.webp",
    stats: {
      Health: { base: 106.25, increment: 1 },
      Stamina: { base: 300, increment: 30 },
      Food: { base: 1000, increment: 100 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 750, increment: 1 },
    },
    description: "Fastest on ground",
    tame_method: "Bola",
    preferred_food: "Chili > Crop > Wild Vege > Fruit, Berry",
  },
  {
    name: "Parrot",
    image: "./images/Parrot.webp",
    stats: {
      Health: { base: 40, increment: 1 },
      Stamina: { base: 150, increment: 15 },
      Food: { base: 900, increment: 900 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 15, increment: 1 },
    },
    description: "Can obtain eggs from trade reward",
    tame_method: "Passive",
    preferred_food: "Berry, hardtack",
  },
  {
    name: "Penguin",
    image: "./images/Penguin.webp",
    stats: {
      Health: { base: 57.5, increment: 1 },
      Stamina: { base: 100, increment: 10 },
      Food: { base: 900, increment: 90 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: Infinity, increment: 0 },
      Weight: { base: 32, increment: 1 },
    },
    description: "Speed Buff on shoulder",
    tame_method: "Passive",
    preferred_food: "Prime Shark, Prime Fish, Fish, Crustacean",
  },
  {
    name: "Pig",
    image: "./images/Pig.webp",
    stats: {
      Health: { base: 110, increment: 1 },
      Stamina: { base: 300, increment: 30 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 190, increment: 1 },
    },
    description: "Big Shhhh",
    tame_method: "Passive",
    preferred_food: "Maize > Corp > Wild Vege > Fruit, Berry",
  },
  {
    name: "Rabbit",
    image: "./images/Rabbit.webp",
    stats: {
      Health: { base: 37.5, increment: 1 },
      Stamina: { base: 120, increment: 12 },
      Food: { base: 600, increment: 60 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 10, increment: 1 },
    },
    description: "Speed Buff on shoulder",
    tame_method: "Passive",
    preferred_food: "Carrot > Berry",
  },
  {
    name: "Razortooth",
    image: "./images/Razortooth.jpeg",
    stats: {
      Health: { base: 267.5, increment: 1 },
      Stamina: { base: 120, increment: 12 },
      Food: { base: 2750, increment: 27.5 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 210, increment: 1 },
    },
    description: "Requires random food from list",
    tame_method: "Bola",
    preferred_food:
      "StuffedBaked, RosemaryChicken, PorkPie, FishNChips, BeefBuns, Cooked PrimeFish, Cooked PrimeMeat",
  },
  {
    name: "Rhino",
    image: "./images/Rhino.webp",
    stats: {
      Health: { base: 337.5, increment: 50.63 },
      Stamina: { base: 190, increment: 19 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 100, increment: 100 },
      Weight: { base: 1200, increment: 24 },
    },
    description: "Stone Farm",
    tame_method: "Bola",
    preferred_food: "Turnip",
  },
  {
    name: "Rock Elemental",
    image: "./images/Rock_Elemental.webp",
    stats: {
      Health: { base: 5156.25, increment: 1 },
      Stamina: { base: 450, increment: 45 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 1200, increment: 1 },
    },
    description: "Requires Token to tame",
    tame_method: "Token",
    preferred_food: "Token",
  },
  {
    name: "Seagull",
    image: "./images/Seagull.webp",
    stats: {
      Health: { base: 42.5, increment: 1 },
      Stamina: { base: 100, increment: 10 },
      Food: { base: 900, increment: 90 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 100, increment: 10 },
      Weight: { base: 15, increment: 1 },
    },
    description: "Capable to encourage players when placed in crafting room.",
    tame_method: "Passive",
    preferred_food: "Berry",
  },
  {
    name: "Seahorse",
    image: "./images/Seahorse.png",
    stats: {
      Health: { base: 250, increment: 1 },
      Stamina: { base: 680, increment: 68 },
      Food: { base: 1750, increment: 175 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: Infinity, increment: 0 },
      Weight: { base: 900, increment: 10 },
    },
    description: "",
    tame_method: "Passive",
    preferred_food: "Seaweed > Seagrapes",
  },
  {
    name: "Sheep",
    image: "./images/Sheep.webp",
    stats: {
      Health: { base: 102.5, increment: 1 },
      Stamina: { base: 160, increment: 16 },
      Food: { base: 1200, increment: 120 },
      Damage: { base: 114, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 90, increment: 1 },
    },
    description: "",
    tame_method: "Passive",
    preferred_food: "Camomile > Berry",
  },
  {
    name: "Shieldhorn",
    image: "./images/Shieldhorn.webp",
    stats: {
      Health: { base: 337.5, increment: 50 },
      Stamina: { base: 170, increment: 17 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 390, increment: 1 },
    },
    description: "",
    tame_method: "Bola",
    preferred_food: "Beet, Chili, Edible Gree, Onion, Pepper, Potato, Turnip",
  },
  {
    name: "Spider",
    image: "./images/Spider.webp",
    stats: {
      Health: { base: 187.55, increment: 1 },
      Stamina: { base: 250, increment: 25 },
      Food: { base: 1000, increment: 100 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 300, increment: 1 },
    },
    description: "climb",
    tame_method: "Bola",
    preferred_food: "Worm, Insect",
  },
  {
    name: "Tiger",
    image: "./images/Tiger.webp",
    stats: {
      Health: { base: 162.5, increment: 1 },
      Stamina: { base: 240, increment: 24 },
      Food: { base: 1200, increment: 120 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 220, increment: 1 },
    },
    description: "Provides bleeding debuff.",
    tame_method: "Bola",
    preferred_food: "Marrow",
  },
  {
    name: "Tortugar",
    image: "./images/Tortugar.png",
    stats: {
      Health: { base: 5625, increment: 1 },
      Stamina: { base: 200, increment: 20 },
      Food: { base: 3500, increment: 350 },
      Damage: { base: 114, increment: 4.45 },
      Oxygen: { base: 600, increment: 60 },
      Weight: { base: 2250, increment: 1 },
    },
    description: "Capable to encourage players when placed in crafting room.",
    tame_method: "Passive",
    preferred_food: "Marrow",
  },
  {
    name: "Turtle",
    image: "./images/Turtle.webp",
    stats: {
      Health: { base: 157.5, increment: 1 },
      Stamina: { base: 200, increment: 20 },
      Food: { base: 3000, increment: 300 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: Infinity, increment: 0 },
      Weight: { base: 270, increment: 1 },
    },
    description: "Capable to encourage players when placed in crafting room.",
    tame_method: "Passive",
    preferred_food: "Berry",
  },
  {
    name: "Vulture",
    image: "./images/Vulture.webp",
    stats: {
      Health: { base: 57.5, increment: 1 },
      Stamina: { base: 100, increment: 10 },
      Food: { base: 900, increment: 90 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 200, increment: 1 },
    },
    description: "",
    tame_method: "Bola",
    preferred_food: "Spoiled Meat, Worms",
  },
  {
    name: "Wolf",
    image: "./images/Wolf.webp",
    stats: {
      Health: { base: 112.5, increment: 1 },
      Stamina: { base: 210, increment: 21 },
      Food: { base: 1500, increment: 150 },
      Damage: { base: 114.04, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 200, increment: 1 },
    },
    description: "",
    tame_method: "Bola",
    preferred_food: "Marrow",
  },
  {
    name: "Yeti",
    image: "./images/Yeti.webp",
    stats: {
      Health: { base: 2500, increment: 1 },
      Stamina: { base: 300, increment: 30 },
      Food: { base: 1500, increment: 150 },
      Damage: { base: 114, increment: 4.45 },
      Oxygen: { base: 150, increment: 15 },
      Weight: { base: 220, increment: 1 },
    },
    description: "description",
    tame_method: "Bola",
    preferred_food: "Ice",
  },
];

function Tame({ someCondition }) {
  const [selectedAnimal, setSelectedAnimal] = useState(animals[0]);
  const [stats, setStats] = useState({});
  const [imprint, setImprint] = useState(false);

  // 로컬 스토리지에서 데이터 불러오기
  useEffect(() => {
    const savedStats = localStorage.getItem(`stats-${selectedAnimal.name}`);
    const savedImprint = localStorage.getItem(`imprint-${selectedAnimal.name}`);
    const savedSelectedAnimal = localStorage.getItem(`selected_animal`);

    if (savedStats) {
      setStats(JSON.parse(savedStats));
    } else {
      resetStats(selectedAnimal);
    }

    if (savedImprint) {
      setImprint(JSON.parse(savedImprint));
    }

    if (savedSelectedAnimal) {
      setSelectedAnimal(JSON.parse(savedSelectedAnimal));
    }
  }, [selectedAnimal]);

  const resetStats = (animal) => {
    const initialStats = Object.keys(animal.stats).reduce(
      (acc, key) => ({
        ...acc,
        [key]: { point: 0, value: animal.stats[key].base },
      }),
      {}
    );
    setStats(initialStats);
  };

  const handleAnimalChange = (e) => {
    const animal = animals.find((animal) => animal.name === e.target.value);
    setSelectedAnimal(animal);
    localStorage.setItem(`selected_animal`, JSON.stringify(animal));
  };

  const handlePointChange = (stat, newPoint) => {
    const point = Math.min(Math.max(Number(newPoint) || 0, 0), 100);
    const { base, increment } = selectedAnimal.stats[stat];
    const newValue = base + increment * point;
    const newStats = {
      ...stats,
      [stat]: { point, value: newValue },
    };
    setStats(newStats);

    // 로컬 스토리지에 스탯 저장
    localStorage.setItem(
      `stats-${selectedAnimal.name}`,
      JSON.stringify(newStats)
    );
  };

  const handleImprintChange = () => {
    const newImprint = !imprint;
    setImprint(newImprint);

    // 로컬 스토리지에 인프린트 상태 저장
    localStorage.setItem(
      `imprint-${selectedAnimal.name}`,
      JSON.stringify(newImprint)
    );
  };

  const totalPoints = Object.values(stats).reduce(
    (acc, stat) => acc + stat.point,
    0
  );
  const level = totalPoints + 1;

  if (!someCondition) {
    return null;
  }

  return (
    <TameContainer>
      <ImageContainer>
        <AnimalImage src={selectedAnimal.image} alt={selectedAnimal.name} />
      </ImageContainer>
      <DropdownContainer className="no-drag">
        <Dropdown value={selectedAnimal.name} onChange={handleAnimalChange}>
          {animals.map((animal) => (
            <option key={animal.name} value={animal.name}>
              {animal.name}
            </option>
          ))}
        </Dropdown>
      </DropdownContainer>
      <div>
        {selectedAnimal.description}
      </div>
      <div>
        Taming Method: {selectedAnimal.tame_method}
      </div>
      <div>
        Preferred Food: {selectedAnimal.preferred_food}
      </div>

      <Divider />

      <LevelDisplay>
        Level: <span>{level}</span>
      </LevelDisplay>

      <Stats className="no-drag">
        {Object.keys(stats).map((stat) => (
          <Stat key={stat}>
            <StatText>{stat}:</StatText>
            <Value>
              <span>{stats[stat].value}</span>
            </Value>
            <PointContainer>
              <PointInput
                type="number"
                value={stats[stat].point}
                onChange={(e) => handlePointChange(stat, e.target.value)}
                min="0"
                max="500"
              />
            </PointContainer>
          </Stat>
        ))}
      </Stats>

      <CheckboxContainer className="no-drag">
        <input
          type="checkbox"
          id="imprint"
          checked={imprint}
          onChange={handleImprintChange}
        />
        <label htmlFor="imprint">Imprint</label>
      </CheckboxContainer>
      <Divider />
      
    </TameContainer>
  );
}

export default Tame;

/* 스타일링 */
const TameContainer = styled.div`
  width: 400px;
  height: auto;
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
`;

const ImageContainer = styled.div`
  margin-bottom: 20px; /* 이미지와 드롭다운 사이의 간격 조정 */
`;

const AnimalImage = styled.img`
  width: auto;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
  user-select: none; /* 텍스트 선택 비활성화 */
  -webkit-user-drag: none; /* 웹킷 기반 브라우저에서 드래그 비활성화 */
  -webkit-touch-callout: none; /* 터치 시 팝업 메뉴 비활성화 */
  pointer-events: none; /* 마우스 이벤트 비활성화 */
  justify-content: center; /* 드롭다운을 중앙에 배치 */
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: center; /* 드롭다운을 중앙에 배치 */
  margin-bottom: 20px; /* 드롭다운과 다른 요소 사이의 간격 조정 */
`;

const Dropdown = styled.select`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
`;

const Divider = styled.hr`
  margin: 20px 0;
`;

const LevelDisplay = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stat = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const StatText = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const Value = styled.div`
  flex: 1;
  margin-right: 20px;
  min-width: 120px; /* 충분한 너비를 설정하여 값이 길어지더라도 버튼이 움직이지 않도록 */
`;

const PointContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PointInput = styled.input`
  width: 60px;
  margin: 0;
  text-align: center;
  font-size: 16px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
