import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';
import {NavLink} from 'react-router-dom';
import Draggable from 'react-draggable';

import Tame from './tame';

function Navbar() {
  const [tameComp, setTameComp] = useState(false);

    const toggleTameComp = () => {
      const newState = !tameComp;
      setTameComp(newState);

      localStorage.setItem(`tameCompStatus`, JSON.stringify(newState));
    };

    useEffect(() => {
      const savedTameComp = localStorage.getItem(`tameCompStatus`);

      if (savedTameComp) {
        setTameComp(JSON.parse(savedTameComp));
      }
    }, []);



  return (
    <Nav>
      <div style={{ height: "2px" }}></div>
      <MainNav>
        <StyledNavLink to={"/atlas"}>OFFICIAL</StyledNavLink>
        <StyledNavLink to={"/atlaswars"}>ATLASWARS</StyledNavLink>
        <StyledNavLink to={"/about/"}>About</StyledNavLink>
      </MainNav>
      <div style={{ height: "2px" }}></div>
      <SubNav>
        <StyledSubNavLink onClick={() => toggleTameComp()}>
          TAME INFO
        </StyledSubNavLink>
      </SubNav>
      <div style={{ height: "3px" }}></div>
      {/* 팝업 */}
      <>
        <Draggable cancel=".no-drag">
          <Popup>
            {tameComp && (
              <>
                <CloseButton onClick={toggleTameComp}>x</CloseButton>
                <Tame someCondition={true} />
              </>
            )}
          </Popup>
        </Draggable>
      </>
    </Nav>
  );
}


const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const Popup = styled.div`
  position: fixed;
  top: 300px;
  left: 100px;
  transform: translate(-50%, -50%);
  background-color: yellow;
  padding: 0px;
  box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.6);
  z-index: 1000;
`;



const Nav = styled.nav`
`;

const MainNav = styled.nav`
  
  width: 100%;
  height: 50px;
  padding: 0px;
  margin: 0;

  display: flex;
  text-decoration: none;
  background-color: #050505;
  border-top: 0px solid #000;
  border-bottom: 0px solid #000;

  
  //justify-content: left; // 가로 방향 중앙 정렬
  //align-items: center; // 세로 방향 중앙 정렬
  margin-left: auto;
  margin-right: auto;
  
`;

const SubNav = styled.nav`
  width: 100%;
  height: 30px;
  padding: 0px;
  margin: 0;

  display: flex;
  text-decoration: none;
  background-color: #050505;

  margin-left: 0px;
  justify-content: left; // 가로 방향 중앙 정렬
  align-items: center; // 세로 방향 중앙 정렬
  
  
`;


const StyledNavLink = styled(NavLink)`

  width: auto;
  color: #aaa; // 링크의 색상
  padding: 15px; // 패딩
  
  font-size: 15px;
  font-family: 'Helvetica Neue',Helvetica;
  
  
  text-decoration: none; // 텍스트 꾸밈 제거
  align-items: center;
  justify-content: center;

  &:hover {
    color: #4caf50; // 호버 상태일 때 색상 변경
  }

  &.active {
    color: #FFF;
    background-color: rgb(43, 126, 33);
  }
  
`;

const StyledSubNavLink = styled(NavLink)`

  width: auto;
  color: #aaa; // 링크의 색상
  padding: 10px; // 패딩
  
  font-size: 10px;
  font-family: 'Helvetica Neue',Helvetica;
  
  
  text-decoration: none; // 텍스트 꾸밈 제거
  align-items: center;
  justify-content: center;

  &:hover {
    color: #4caf50; // 호버 상태일 때 색상 변경
  }

  &.active {
    color: #FFF;
    background-color: rgb(43, 126, 33);
  }
  
`;

export default Navbar;